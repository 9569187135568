import React, { useEffect, useRef, useState } from 'react'
import { BugOutline } from 'react-ionicons';
import PopUp from './components/global/PopUp';
import TextArea from './components/global/inputs/placeholderAbove/TextArea';
import ButtonPrimary from './components/global/buttons/ButtonPrimary';
import CallAPI from './components/functional/CallAPI';
import { useNotification } from './components/global/Notifications';
import styled from 'styled-components'

// TODO remove component when done with bug testing

const StyledBugButton = styled.div`
    z-index: 10000;
    position: fixed;
    left: 0;
    bottom: 0;

    transform: rotate(45deg) scale(1.25) translateY(50%);

    transition: transform .5s;
    padding: .5rem;

    &.active {
        transform: rotate(45deg) scale(1.25)
    }
`

const BugReport = () => {
    const [bugActive, setBugActive] = useState(false)
    const [showPopUp, setShowPopUp] = useState(false)
    const [bugText, setBugText] = useState('')
    const bugRef = useRef(null)

    const createNotification = useNotification()

    const submitBug = () => {
        CallAPI('/api/v1/app/bugReport', "POST", {
            bugText: bugText,
            protocol: window.location.protocol,
            path: window.location.pathname+window.location.search
        })
        .then(data => {
            if (data.error !== undefined) return createNotification({
                type: 'danger', 
                message: <span><b>Could not report bug:</b>{data.error}</span>,
            })

            setShowPopUp(false)
            setBugText('')
            
            return createNotification({
                type: 'success',
                message: <b>Bug reported successfully!</b>,
                timeout: 3000,
            })
        })
    }

    const handleBugClickOutside = (e) => {
      if (bugRef.current && !bugRef.current.contains(e.target)) {
        setBugActive(false)
      }
    }

    useEffect(() => {
        if (bugActive) {
          document.addEventListener('click', handleBugClickOutside)
        } else {
          document.removeEventListener('click', handleBugClickOutside)
        }
    
        return () => {
          document.removeEventListener('click', handleBugClickOutside)
        }
    }, [bugActive])

    return (
        <>
        <StyledBugButton 
            className={(bugActive ? 'active' : '')} 
            onClick={() => {
                if (!bugActive) {
                    setBugActive(true)
                } else {
                    setShowPopUp(true)
                    setBugActive(false)
                }
            }} 
            ref={bugRef}
        >
            <BugOutline color={'var(--primary)'}/>
        </StyledBugButton>
        <PopUp showPopUp={showPopUp} setShowPopup={setShowPopUp} >
            <h3>Report Bug:</h3>
            <TextArea style={{height: '9rem'}} value={bugText} setValue={(e) => setBugText(e.target.value)} maxLength={300}/>
            <ButtonPrimary text='Submit' onClick={() => submitBug()} />
        </PopUp>
        </>
     )
}

export default BugReport