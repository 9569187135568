import React, { useState, useEffect } from "react"

import CallAPI from '../../components/functional/CallAPI'

import SplashScreen from '../../pages/SplashScreen'

import DefaultProfPic from '../../images/default_teal.png'
import Input from "../../components/global/inputs/elementInset/Input"
import ButtonPrimary from "../../components/global/buttons/ButtonPrimary"
import { useNotification } from "../../components/global/Notifications"


const UserEntityBody = ({ id, state, updateState, SubmitPopUp }) => {
    const createNotification = useNotification()
    const [form, setForm] = useState({
        first: '',
        last: '',
        username: '',
        role: '',
        phone: ''
    })
    const [profilePic, setProfilePic] = useState(null)

    const [originalUsername, setOriginalUsername] = useState('')

    const [loading, setLoading] = useState(true)

    const handleUpdate = (e) => {
        setForm(prev => ({...prev, [e.target.name]: e.target.value}))
        updateState("formChanged", true)
    }

    const updateForm = (key, value) => {
        setForm(prev => ({...prev, [key]: value}))
    }

    const usernameAvailable = async (username) => {
        if (originalUsername == username) return true
        return CallAPI("/api/v1/user/availability?username="+(form.useDefault ? form.defaultUsername : form.username))
            .then(result => {
              if (result.error || result.available === undefined) return (
                createNotification({
                    type: 'danger',
                    message: "Could not validate username.",
                    timeout: 3500,
                })
              )
              
              if (result.available === 'true') {
                return true
              } else {
                return false
              }
        })
    }

    const handleSubmit = () => {
        usernameAvailable(form.username)
        .then(available => {
            if (!available) return (
                createNotification({
                    type: 'danger',
                    message: 'The selected username is taken.',

                })
            )

            CallAPI('/api/v1/user', 'PATCH', {
                name: `${form.first.trim()} ${form.last.trim()}`.trim(),
                username: form.username,
                role: form.role,
                phone: form.phone,
                id: id,
            })
            .then(result => {
                if (result.error || result.length === 0) return (
                    createNotification({
                        type: 'danger',
                        message: <span><b>Submission Error:</b>{result.error}</span>,
                    })
                )
    
                window.location.reload()
            })
        })
    }

    const handlePasswordReset = () => {
        CallAPI('/api/v1/user/password', 'POST', {
            uid: id
        })
        .then(result => {
            if (result.error || result.length === 0) return (
                createNotification({
                    type: 'danger', 
                    message: <span><b>Could Not Reset Password:</b>{result.error}</span>,
                    timeout: 4000,
                })
            )

            createNotification({
                type: 'success', 
                message: 'Password reset successfully',
                timeout: 3500, 
            })
        })
    }

    useEffect(() => {
        setLoading(true)
        CallAPI('/api/v1/user?id='+id)
        .then(result => {
            setLoading(false)
            if (result.error || result.length === 0) return (
                createNotification({
                    type: 'danger',
                    message: result.error,
                })
            )

            result = result[0]
            
            setProfilePic(result.profilePic)
            updateForm('first', result.name.split(' ')[0])
            result.name.split(' ')[1] && updateForm('last', result.name.split(' ')[1])
            updateForm('username', result.username)
            setOriginalUsername(result.username)
            updateForm('role', result.role)
            result.phone && updateForm('phone', result.phone)
        })
    }, [])

    return (
        !loading ? (<>
            {state.showSubmitPopUp && <SubmitPopUp handleSubmit={handleSubmit}/>}

            <div className='entityViewCard'>
                <div>
                    <div className='profilePic entityViewCardPic'>
                        <img 
                            src={profilePic ? `data:image/png;base64,${profilePic}` : DefaultProfPic}
                            alt='Profile Picture'
                        />
                    </div>

                    <div className='entityViewCardName w-100'>
                        <h2 className="pe-1">{!state.editing ? 
                            `${form.first} ${form.last}` 
                        : 
                            <>
                            <Input 
                                name='first' 
                                placeholder="First" 
                                value={form.first} 
                                setValue={handleUpdate} 
                                maxLength={20}
                            /> 
                            <Input 
                                name='last' 
                                placeholder="Last" 
                                value={form.last} 
                                setValue={handleUpdate} 
                                maxLength={20}
                            />
                            </>
                        }</h2>

                        <h3 className="pe-1">{!state.editing ? 
                            form.username 
                        : 
                            <Input 
                                name='username' 
                                placeholder="Username" 
                                value={form.username} 
                                maxLength={40}
                                setValue={handleUpdate} 
                                onBlur={() => {
                                    usernameAvailable(form.username)
                                    .then(available => {
                                        if (available) return
                                        createNotification({
                                            type: 'warn', 
                                            message: 'The selected username is taken.',
                                            timeout: 3500,
                                        })
                                    }) 
                                }}
                            />
                        }</h3>
                    </div>
                </div>
                <div>
                    <h3>{!state.editing ? form.role : 
                        <>
                        <ButtonPrimary 
                            text="User" 
                            onClick={() => {
                                setForm(prev => ({...prev, role: 'user'}))
                                updateState("formChanged", true)
                            }}
                            className={'me-1' + (form.role != 'user' ? ' manageUsersButtonSecondary' : '')}
                        />

                        <ButtonPrimary 
                            text="Manager" 
                            onClick={() => {
                                setForm(prev => ({...prev, role: 'manager'}))
                                updateState("formChanged", true)
                            }}
                            className={form.role != 'manager' ? 'manageUsersButtonSecondary' : ''}
                        />
                        </>
                    }</h3>

                    <h3 className="w-100 ps-1">{!state.editing ? form.phone : <Input name='phone' placeholder="Phone" value={form.phone} setValue={handleUpdate} isPhoneNumber={true} maxLength={14} />}</h3>

                    {state.editing && 
                        <ButtonPrimary 
                            text="Reset Password" 
                            className="bg-danger mt-2"
                            onClick={() => handlePasswordReset()}
                        />
                    }

                </div>
            </div>
        </>) : (
            <SplashScreen />
        )
    )
}

export default UserEntityBody