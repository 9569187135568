import React, {useState, useEffect} from 'react'

import BarChartTemplate from '../charts/templates/BarChartTemplate'

import { HashLoader } from 'react-spinners'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const JobsByMonth = ({ data, loadingError }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true)

        if (data || loadingError) {
            setLoading(false)
        }

    }, [data, loadingError])

  return (
    <div className='widget'>
        {!loading ? (
            !loadingError ? (
                <>   
                    <div className='graphContainer'>
                        <h2 className='graphWidgetHeader'>Jobs By Month</h2>
                        <BarChartTemplate data={data} xAxis="month" fields={['count']} metadata={'year'} onClick={(info) => {
                            const month = (months.findIndex(month => month.toLocaleLowerCase() === info.data.toLocaleLowerCase())+1).toString().padStart(2, '0')
                            const startDate = `${info.metadata}-${month}-01`
                            const endDate = `${info.metadata}-${month}-${(new Date(info.metadata, month, 0).getDate())}`

                            window.location.href = `/archive?entity_filter=job&start_date=${startDate}&end_date=${endDate}`
                        }} />
                    </div>
                </>
            ) : (
                <div className='centerChildX'>
                    <h3 className='color-danger'>
                        Could not load.
                    </h3>  
                </div>
            )
        )   : (
            <div className='spinner'>
                <HashLoader />
            </div>
        )}
    </div>
  )
}

export default JobsByMonth