import React, { useState } from 'react'
import styled from 'styled-components'

import { handleChange } from '../CommonUtils'

const StyledInput = styled.input`
    all: unset;

    max-width: 100%;
    padding: 10px;
    box-sizing: border-box;
    background-color: var(--bg-secondary, rgb(245, 249, 251));

    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;

    &:hover, &:focus {
        box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
    }

    &[contenteditable=false] {
        pointer-events: none;
        background: var(--greyOut, rgba(128, 128, 128, 0.422));
    }
`

const Input = ({
    value,
    setValue=()=>{},
    name,
    type='text',
    maxLength,
    placeholder="Type here...",
    className='',
    numbersOnly=false,
    contentEditable=true,
    isPhoneNumber=false,
    onBlur=()=>{},
    onEnter=()=>{}
}) => {
    const [error, setError] = useState()

    return (
        <StyledInput
            type={type}
            value={value}
            name={name}
            placeholder={placeholder}
            className={className}
            contentEditable={contentEditable}
            maxLength={isPhoneNumber ? 14 : maxLength}
            onChange={(e) => handleChange(e, numbersOnly, isPhoneNumber, setValue, setError)}
            onBlur={() => onBlur()}
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    onEnter()
                }
            }}
        />
    )
}

export default Input