import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './css/App.css';

import Dashboard from './pages/Dashboard/Dashboard'
import LoginPage from './pages/LoginPage'
import Messages from './pages/Messages';
import Archive from './pages/Archive';
import Jobs from './pages/priveleged/Jobs';
import Users from './pages/priveleged/Users';
import Vehicles from './pages/priveleged/Vehicles';
import Settings from './pages/Settings';
import Scraper from './plugins/Scraper';
import SplashScreen from './pages/SplashScreen';
import EntityInfo from './pages/EntityInfo/EntityInfo';

import AuthContext from './components/functional/AuthContext';
import CallAPI from './components/functional/CallAPI';
import NotificationProvider from './components/global/Notifications';
import TokenData from './components/functional/TokenData';
import ErrorPage from './404';
import BugReport from './BugReport'; // TODO remove once done with bug testing



const userRole = TokenData()?.role

const App = () => {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            token: action.token,
            isLoading: false,
          }
        case 'LOG_IN':
          localStorage.setItem('JWT', action.token);
          window.location.reload()
          return {
            ...prevState,
            isLogout: false,
            token: action.token,
          }
        case 'LOG_OUT':
          localStorage.removeItem('JWT');
          window.location.href = '/'
          return {
            ...prevState,
            isLogout: true,
            token: null,
          }
        default:
          break
      }
    },  
    {
      isLoading: true,
      isLogout: false,
      token: null,
    }
  );
  
  const authContext = React.useMemo(
    () => ({
      logIn: async (username, password) => {
        return CallAPI('/api/v1/auth', 'POST', {'username': username, 'password': password}, undefined, true)
        .then((result) => {          
          if (result.error === undefined && !(result.new && result.new == 1)) {
            dispatch({ type: 'LOG_IN', token: result.token })
          }

          return result
        })
        .catch(_ => {
          return { error: 'There was a processing error.' }
        })
      },
  
      logOut: () => dispatch({ type: 'LOG_OUT' }),

      newUserLogIn: (token) => dispatch({ type: 'LOG_IN', token: token }),
    }),
    []
  );

  useEffect(() => {
    try {
      const token = localStorage.getItem("JWT")
      
      if (token === null) {
        dispatch({type: 'RESTORE_TOKEN', token: null})
        return
      }
      
      CallAPI('/api/v1/auth/validateToken', 'GET')
      .then(data => {
        data === true ? dispatch({type: 'RESTORE_TOKEN', token: token}) : dispatch({type: 'RESTORE_TOKEN', token: null})

      })
      .catch(err => {
        dispatch({type: 'RESTORE_TOKEN', token: null})
        return
      })
      

    } catch {
      dispatch({type: 'RESTORE_TOKEN', token: null})
    }   

  }, [])

  return (
    <NotificationProvider>
      <AuthContext.Provider value={authContext}>
        {state.isLoading ? (
          <SplashScreen/>
        ) : (
          state.token == null ? (
            <LoginPage />
          ) : (
            <>
            <BugReport />
            {
            // TODO remove ^ once done with bug testing
            }
            <Router>
              <Routes path='/'>
                <Route index element={<Dashboard />} />
                <Route path='login' element={<LoginPage />} />
                {/* TODO remove when ready for messages page
                <Route path='messages' element={<Messages />} />
                */}
                <Route path='settings' element={<Settings />} />
                <Route path='archive' element={<Archive />} />
                <Route path='settings' element={<Settings />} />
                <Route path='entity_info' element={<EntityInfo />} />

                {userRole === 'manager' && (<>
                  <Route path='management/jobs' element={<Jobs />} />
                  <Route path='management/users' element={<Users />} />
                  <Route path='management/vehicles' element={<Vehicles />} />
                  <Route path='plugins/scraper' element={<Scraper />} />
                </>)}

                <Route path='*' element={<ErrorPage />} />
              </Routes>
            </Router>
            </>
          )
        )}
      </AuthContext.Provider>
    </NotificationProvider>
  )
}

export default App;
