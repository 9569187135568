import React, { useRef } from 'react'
import styled from 'styled-components'

const Container = styled.div`
    --width: 18rem;
    --padding: 20px;

    position: absolute;
    width: var(--width);
    height: 100%;
    top: 0;
    bottom: 0;
    left: 100%;

    overflow-y: scroll;
    overflow-x: hidden;

    background-color: var(--bg-secondary-offset);

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 var(--padding) 0 var(--padding);

    transition: left 500ms ease-in-out, box-shadow 500ms;

    &.open {
        left: calc(100% - (var(--width) + var(--padding)*2));
        box-shadow: -5px 5px 8px grey;
    }
`

const SlidingSidebar = ({ children, open, className='' }) => {
    return (
        <Container className={className + (open ? ' open' : '')} >
            {children}
        </Container>
    )
}

export default SlidingSidebar