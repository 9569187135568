import React, { useEffect, useState } from 'react'

import WeatherWidget from '../../components/widgets/WeatherWidget'
import TodaysJobs from '../../components/widgets/TodaysJobs'
import JobsByType from '../../components/widgets/JobsByType'
import JobsByMonth from '../../components/widgets/JobsByMonth'

import Calendar from '../../components/widgets/Calendar'

import TokenData from '../../components/functional/TokenData'
import CallAPI from '../../components/functional/CallAPI'
import { useNotification } from '../../components/global/Notifications'
import UnassignedJobs from '../../components/widgets/UnassignedJobs'


const userRole = TokenData()?.role


const Greeting = () => {
    
    let user = !TokenData() ? '' : TokenData().name.split(' ')[0]

    // set greeting based on hour of the day
    let hour = new Date().getHours()
    let timeOfDay;
    if(hour < 12) {
        timeOfDay = " Morning ";
    } else if (hour >= 12 && hour < 18) {
        timeOfDay = " Afternoon ";
    } else if (hour >= 18){
        timeOfDay = " Evening ";
    }

    return (
        <h2 className='dashboardGreeting widgetLg'>
            {"Good" + timeOfDay + user}
        </h2>
    )
}

const getCoords = (allowLocation) => {
    return new Promise((resolve) => {
        if (parseInt(allowLocation)) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    resolve(position.coords.latitude + ',' + position.coords.longitude);
                }, () => {
                    resolve(null)
                });

            } else {
                console.warn("Geolocation is not supported by this browser.");
                resolve(null);
            }
        } else {
            resolve(null);
        }
    })
}


const DashboardMain = () => {
    const createNotification = useNotification()
    const [dashData, setdashData] = useState(null)
    const [loadingError, setLoadingError] = useState(false)

    useEffect(() => {
        CallAPI('/api/v1/user/settings')
        .then(result => {
            if (result.error) return (
                createNotification({
                    type: 'danger', 
                    message: 'Could not access user settings.',
                    timeout: 4000,
                })
            )

            getCoords(result.allow_location)
            .then(coords => {
                CallAPI('/api/v1/dash'+(coords ? ('?coords='+coords) : ''))
                .then(result => {
                    if (result.error) return setLoadingError(true)

                    setdashData(result)
                })
            })
        })
    }, [])

  return (
    <div className='dashboardMainContainer'>
        <div className='dashboardWidgetContainer'>
            <Greeting />

            <TodaysJobs />

            <div className='widget widgetTall'><Calendar /></div>

            <WeatherWidget data={dashData?.weather} loadingError={loadingError}/>

            {userRole === 'manager' &&<div className='widget'>{<UnassignedJobs />}</div>}

            {userRole === 'manager' && <JobsByType data={dashData?.jobsByType} loadingError={loadingError}/>}

            {userRole === 'manager' && <JobsByMonth data={dashData?.jobsByMonth} loadingError={loadingError}/>}
        </div>
    </div>
  )
}

export default DashboardMain 