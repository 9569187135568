/*
    Purpose: Establishes basic functionality for pie char elements
    Author: Amos Gordon

    Latest Upadte: 6/24/2023
*/

import React from 'react'

import { 
    PieChart,
    Pie, 
    Tooltip, 
    ResponsiveContainer,
    Cell
} from 'recharts';


const PieChartTemplate = ({ data, field, value, onClick }) => {
    const COLORS = ["var(--primary, rgb(21, 116, 91))", "var(--secondary, rgb(163, 217, 213))"];

  return (
    <ResponsiveContainer className={"graph"}> 
        <PieChart>
          <Pie
            dataKey={value}
            nameKey={field}
            isAnimationActive={true}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            label
          >     
            {data.map((entry, index) => (
                <Cell
                key={index}
                fill={COLORS[index % COLORS.length]}
                onClick={() => {
                    onClick && (
                        onClick(entry[field])
                    )
                }}
                />
            ))}       
          </Pie>
          <Tooltip />
        </PieChart>
    </ResponsiveContainer>
  )
}

export default PieChartTemplate