import React, {useState, useEffect} from 'react'

import PieChartTemplate from '../charts/templates/PieChartTemplate';

import { HashLoader } from 'react-spinners';

const JobsByType = ({ data, loadingError }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true)

        if (data || loadingError) {
            setLoading(false)
        }

    }, [data, loadingError])

  return (
    <div className='widget'>
        {!loading ? (
            !loadingError ? (
                <div className='graphContainer'>
                    <h2 className='graphWidgetHeader'>Jobs By Type</h2>
                    <PieChartTemplate data={data} field={'type'} value={"count"} onClick={(field) => {
                        window.location.href = '/archive?entity_filter=job&type='+field
                    }} />
                </div>
            ) : (
                <div className='centerChildX'>
                    <h3 className='color-danger'>
                        Could not load.
                    </h3>  
                </div>
            )
        )   : (
            <div className='spinner'>
                <HashLoader />
            </div>
        )}
    </div>
  )
}

export default JobsByType