/*
    Purpose: Establishes basic functionality for bar char elements
    Author: Amos Gordon

    Latest Upadte: 6/24/2023
*/

import React from 'react'

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts"

const BarChartTemplate = ({ 
  data, 
  xAxis, 
  fields=[], 
  metadata,
  onClick=() => {} 
}) => {
  return (
    <ResponsiveContainer>
        <BarChart
            data={data}
        >
            <CartesianGrid strokeDasharray={"20 3"} />
            <XAxis dataKey={xAxis} />
            <YAxis />
            <Tooltip />
            <Legend />
            {fields.map((field, index) => 
              <Bar 
                dataKey={field} 
                key={index} 
                fill="var(--primary, rgb(21, 116, 91))" 
                onClick={(_, clickedIndex) => {onClick && (
                  onClick({data: data[clickedIndex][xAxis], metadata: data[clickedIndex][metadata]})
                )}}
              />
            )}
        </BarChart>
    </ResponsiveContainer>
  )
}

export default BarChartTemplate