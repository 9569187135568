import React, { useState } from 'react'

import CallAPI from '../../components/functional/CallAPI'

import ButtonPrimary from '../../components/global/buttons/ButtonPrimary'
import Input from '../../components/global/inputs/elementInset/Input'

import { 
  ManageCard, 
  ManageCardBody, 
  ManageCardFooter, 
  ManageCardHeader
} from '../../components/buildingBlocks/ManageCard'

import VehiclesBackground from '../../images/vehicles_bg.jpg'
import {
  TextOutline,
  Checkmark,
} from 'react-ionicons'
import TextArea from '../../components/global/inputs/elementInset/TextArea'
import Overlay from '../../components/global/Overlay'
import { useNotification } from '../../components/global/Notifications'

const steps = [
  {'icon': <TextOutline />, 'complete': false},
  {'icon': <Checkmark />, 'complete': false},
]

const Vehicles = () => {
  const createNotification = useNotification()
  const [currentPage, setCurrentPage] = useState(0)
  const [formComplete, setFormComplete] = useState(false)

  const [form, setForm] = useState({
    name: '',
    license: '',
    vin: '',
    description: '',
  })

  const handleUpdate = (e) => {
    setForm(prev => ({...prev, [e.target.name]: e.target.value}))
  }

  const pages = [
    {
      "header": 'Vehicle Name:',
      "body":
        <>
          <Input name='name' placeholder='Name' value={form.name} setValue={handleUpdate} maxLength={30} />
          <Input name='license' placeholder='License Plate #' value={form.license} setValue={handleUpdate} maxLength={10} />
          <Input name='vin' placeholder='VIN #' value={form.vin} setValue={handleUpdate} maxLength={17} />
          <TextArea name='description' placeholder='Description' value={form.description} setValue={handleUpdate} maxLength={30}/>
        </>,
        'requirements': [form.name],
        'requirementFieldNames': ['Name']
    },
    {
      "header": 'Confirm:',
      "body": 
      <>
        {Object.keys(form)?.map((key, index) => (
          <span key={index}>
            {form[key]}
          </span>
        ))}
      </>,
    }
  ]

  const handleSubmit = () => {
    CallAPI('/api/v1/vehicle', 'POST', {
      ...form
    })
    .then(result => {
      if (result.error) {
        createNotification({
          type: 'danger', 
          message: result.error,
        })
      } else {
        setFormComplete(true)
      }
    })
  }

  return (
    <>
    <Overlay show={formComplete}>
      <div className='overlayTitle'>
        Success
      </div>

      <div className='overlayButtonGroup'>
        <ButtonPrimary text='Make Another' onClick={() => window.location.href = '/management/vehicles'}/>
        <ButtonPrimary text='Done' onClick={() => window.location.href = '/'} />        
      </div>
    </Overlay>

    <img className='manageBackground' src={VehiclesBackground} alt='Background' />

    <div className='manageContainer'>      
      <ManageCard>
        <ManageCardHeader>
          {pages[currentPage]?.header}
        </ManageCardHeader>

        <ManageCardBody>
          {pages[currentPage]?.body}
        </ManageCardBody>

        <ManageCardFooter 
          pages={pages}
          steps={steps}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handleSubmit={handleSubmit}
        />
      </ManageCard>
    </div>
    </>
  )
}

export default Vehicles