import React, { createContext, useContext, useEffect, useReducer, useState } from 'react'
import {v4} from 'uuid'

export const NotificationContext = createContext()

const NotificationProvider = ({children}) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case 'ADD_NOTIFICATION':
                if (state.active.length > 2) {
                    return {
                        ...state, 
                        queue: [...state.queue, {...action.payload}]
                    }
                }
                return {
                    ...state, 
                    active: [...state.active, {...action.payload}]
                }

            case 'REMOVE_NOTIFICATION': 
                if (state.active.length > 2 && state.queue.length > 0) {
                    return {
                        active: [
                            ...state.active.filter(notif => notif.id !== action.id), 
                            {...state.queue[0]}
                        ], 
                        queue: [...state.queue.slice(1)]
                    }
                }
                return {...state, active: state.active.filter(notif => notif.id !== action.id)}

            default: 
                return state
        }
    }, {
        active: [],
        queue: [],
    })


    return (
        <NotificationContext.Provider value={dispatch}>
            <div className='notificationContainer'>
                {state.active.map((notification) => (
                    <Notification key={notification.id} content={notification} dispatch={dispatch} />
                ))}
            </div>
            {children}
        </NotificationContext.Provider>
    )
}

const Notification = ({ content, dispatch }) => {
    const [width, setWidth] = useState(0)
    const [intervalID, setIntervalID] = useState(null)
    const [exit, setExit] = useState(false)

    const handleStartTimer = () => {
        if (!content.timeout) return 
        const id = setInterval(() => {
            setWidth(prev => {
                if (prev < 100) {
                    return prev + 0.5
                }

                return prev
            })
        }, content.timeout/200);

        setIntervalID(id)
    }

    const handlePauseTimer = () => {
        if (!content.timeout) return 
        clearInterval(intervalID)
    }

    const handleClose = () => {
        handlePauseTimer()
        setExit(true)
        setTimeout(() => {
            dispatch({
                type: 'REMOVE_NOTIFICATION',
                id: content.id
            })
        }, 400);
    }

    useEffect(() => {
        if (content.timeout) {
            handleStartTimer()
        }
    }, [])

    useEffect(() => {
        if (width >= 100) {
            handleClose()
        }
    }, [width])

    return (
        <div 
            className={'notification' + (exit ? ' exit' : '')}
            onMouseEnter={handlePauseTimer}
            onMouseLeave={handleStartTimer}
            onClick={() => {
                handleClose()
            }}
            style={{'--notificationBorderColor': (!content.timeout ? `var(--${content.type})` : '')}}
        >
            <div className='content'>
                {content.message}
            </div>
            {content.timeout && (
                <div 
                    className={'bar' + (content.type ? ` bg-${content.type}` : '')} 
                    style={{width: `${width}%`}}
                ></div>
            )}
        </div>
    )
}

export const useNotification = () => {
    const dispatch = useContext(NotificationContext)

    return (props) => {
        dispatch({
            type: "ADD_NOTIFICATION",
            payload: {
                id: v4(),
                ...props
            }
        })
    }
}

export default NotificationProvider
