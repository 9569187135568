import React, { useEffect, useState } from 'react'
import CallAPI from '../functional/CallAPI'
import { useNotification } from '../global/Notifications'

const IncompleteJobs = () => {
    const createNotification = useNotification()

    const [jobs, setJobs] = useState([])
    const [error, setError] = useState(null)

    useEffect(() => {
        setError(null)
        CallAPI('/api/v1/job/incomplete')
        .then(result => {
            if (result.error) {
                createNotification({
                    type: 'error',
                    message: <span><b>Unassigned Jobs Error:</b>{result.error}</span>,
                    timeout: 3500,
                })
                setError('Could not load.')

                return 
            } 

            setJobs(result)
        })
    }, [])

    const formatDate = (date) => {
        if (!date) return 
        const split = date?.split('-')
        return `${parseInt(split[1])}/${parseInt(split[2])}/${split[0]}`
    }
    
    return (
        <>
        <div className='unassignedJobsLabel'>Unassigned Jobs</div>
        {!error ? (
            <div className='fullScrollableContainer unassignedJobsContainer'>
                {jobs.map((job, index) => {
                    let message = null
                    
                    if (job.notSet === 'user') {
                        message = 'No users assigned.'
                    } else if (job.notSet === 'date') {
                        message = 'No date assigned.'
                    }
                    return (
                        <div key={index} className='unassignedJob hover-pointer' onClick={() => {window.location.href='/entity_info?entity=job&id='+job.job_id}}>
                            <div className='unassignedJobHeader'>   
                                <div className='unassignedJobCustomer'>{job.customer}</div>
                                <div>{formatDate(job.date)}</div>
                            </div>
                            
                            {message}
                        </div>
                    )
                })}
            </div>
        ) : (
            <div className='centerChildX'>
                <h3 className='color-danger'>
                    {error}
                </h3>
            </div>
        )}
        </>
    )
}

export default IncompleteJobs