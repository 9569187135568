/*
    Purpose: Standardizes API calls and returned error codes
    Author: Amos Gordon

    Latest Upadte: 6/24/2023
*/

import axios from 'axios'

const API_URL = process.env.REACT_APP_API_BASE_URL

/**
 * 
 * @param {string} resource API string excluding domian | ex: '/api/v1/user'
 * @param {string} method GET POST PATCH DELETE
 * @param {Array} body data to be sent | GET must be in URL params
 * @param {Array} headers form headers (default is JSON)
 * @param {boolean} isLogin adds login-specific functionality
 * @returns json - {msg: {MESSAGE}, {OPTIONAL DATA}} 
 */
const CallAPI = async (resource, method, body = {}, headers={}, isLogin = false) => {
  try {
    const config = {
      url: API_URL + resource,
      method: method,
      headers: headers ? (headers) : {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      data: body && body
    };
    
    if (!isLogin) {
      const token = localStorage.getItem('JWT');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    } 

    return axios(config)
    .then(response => {
      return response.data ? response.data : true
    })
    .catch(error => {
      return {error: error.response.data.msg ? error.response.data.msg : 'There was a processing error.'}
    })
  } catch {
    return {error: 'There was a processing error.'}
  }
}

export default CallAPI