// This file contains utility functions used by multiple input components


export const handleChange = ( e, numbersOnly, isPhoneNumber, setValue, setError ) => {
    const verifyNumberInput = (value) => {
        if (!(/^-$|^$|^-?\d*\.?\d+$|^-?\d*\.$/).test(value)) {
            setError('Please enter a digit *including negatives and/or decimals*')
            return false
        }
        setError()
        return true
    }

    if (numbersOnly && !verifyNumberInput(e.target.value)) {return}

    if (isPhoneNumber) {
        e.target.value = e.target.value.replace(/\D/g, '');
        const length = e.target.value.length

        if (length > 3 && length <= 6) {
        e.target.value = `(${e.target.value.slice(0, 3)}) ${e.target.value.slice(3, 6)}`
        } else if (length > 6) {
        e.target.value = `(${e.target.value.slice(0, 3)}) ${e.target.value.slice(3, 6)}-${e.target.value.slice(6, 10)}`
        }
    }

    setValue(e)
}