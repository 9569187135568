import React, { useContext, useEffect, useState } from 'react'

import { ArrowBack } from 'react-ionicons'

import SliderToggle from '../components/global/inputs/SliderToggle'

import CallAPI from '../components/functional/CallAPI'
import ButtonPrimary from '../components/global/buttons/ButtonPrimary'
import Input from '../components/global/inputs/elementInset/Input'
import AuthContext from '../components/functional/AuthContext'
import PopUp from '../components/global/PopUp'
import { useNotification } from '../components/global/Notifications'
import TokenData from '../components/functional/TokenData'


const userRole = TokenData()?.role

const Settings = () => {
  const { logOut } = useContext(AuthContext)

  const createNotification = useNotification()

  const [form, setForm] = useState({
    allow_location: false,
    dark_mode: false,
    default_location: '',
    default_password: '',
  })

  const [functional, setFunctional] = useState({
    settingType: 'user',
    userSettingsChanged: false,
    appSettingsChanged: false,
    showPopUp: false
  })

  useEffect(() => {
    CallAPI("/api/v1/user/settings")
    .then(data => {
      if (data.error !== undefined) return (
        createNotification({
          type: 'danger',
          message: data.error,
          timeout: 5000,
        })
      )
      
      setForm(prev => ({...prev, ...data}))
    })

    userRole === 'manager' && CallAPI("/api/v1/app/settings")
    .then(data => {
      if (data.error !== undefined) return (
        createNotification({
          type: 'danger',
          message: <span><b>App Settings:</b>{data.error}</span>,
          timeout: 5000,
        })
      )
      
      setForm(prev => ({...prev, ...data}))
    })  
  }, [])

  const handleChange = (field, value) => {
    setForm(prev => ({
      ...prev,
      [field]: value
    }))

    functional.settingType == 'user' && setFunctional(prev => ({...prev, userSettingsChanged: true}))
    functional.settingType == 'app' && setFunctional(prev => ({...prev, appSettingsChanged: true}))
  }

  const  handleSubmit = async () => {
    return await Promise.all([
      functional.userSettingsChanged && CallAPI("/api/v1/user/settings", "PATCH", {allow_location: !!form.allow_location, dark_mode: !!form.dark_mode}),
      functional.appSettingsChanged && userRole === 'manager' && CallAPI("/api/v1/app/settings", "PATCH", {default_location: form.default_location, default_password: form.default_password}),
    ])
    .then(data => {      
      if (data[0]?.error) {
        createNotification({
          type: 'danger',
          message: <span><b>User Settings:</b>{data[0].error}</span>,
        })
      }

      if (data[1]?.error) {
        createNotification({
          type: 'danger',
          message: <span><b>App Settings:</b><span style={{whiteSpace: 'pre-wrap'}}>{data[1].error}</span></span>,
        })
      }

      if (data[0].error || data[1].error) return false

      createNotification({
        type: 'success', 
        message: 'Settings Changed!',
        timeout: 3500,
      })
      setFunctional(prev => ({...prev, userSettingsChanged: false, appSettingsChanged: false}))
      return true
    })
  }


  const handleLogOut = () => {
    CallAPI('/api/v1/auth/logout', 'POST')
    .then(_ => {
      logOut()
    })
  }

  const settingTypes = [
    {
      label: "User"
    },
    {
      label: "App",
      role: 'manager'
    }
  ].filter(type => type.role === userRole || !type.role)

  const settings = {
    user: {
      title: "General",
      list: [
        {
          title: "Allow Location",
          input: 
            <SliderToggle 
              value={form.allow_location} 
              setValue={() => handleChange("allow_location", !form.allow_location)} 
              onColor={"var(--primary)"}
            />
        },
        // {
        //   title: "Dark Mode",
        //   input: 
        //   <>
        //     <SliderToggle 
        //       value={form.dark_mode} 
        //       setValue={() => handleChange("dark_mode", !form.dark_mode)} 
        //       onColor={"var(--primary)"}
        //     />
        //   </>
        // }
      ]
    },
    app: {
      title: 'Application',
      list: [
        {
          title: 'Default Location',
          input: <Input value={form.default_location} setValue={(e) => handleChange('default_location', e.target.value)} maxLength={72}/>
        },
        {
          title: 'Default Password',
          input: <Input value={form.default_password} setValue={(e) => handleChange('default_password', e.target.value)} maxLength={72} type='password'/>
        }
      ]
    }
  }

  return (
    <div className='settingsContainer'>
      <PopUp 
        title={'Would you like to save your changes?'}
        buttons={[
          <ButtonPrimary text='Yes' onClick={() => {handleSubmit().then(success => success && (window.location.href = '/')) 
        }} />,
          <ButtonPrimary text='No' className='bg-danger' onClick={() => {window.location.href = '/'}} />,
          <ButtonPrimary text='Cancel' className='bg-grey' onClick={() => {setFunctional(prev => ({...prev, showPopUp: false}))}} />

        ]}
        showPopUp={functional.showPopUp}
        setShowPopup={() => setFunctional(prev => ({...prev, showPopUp: false}))}
      />  
      
      <div className='settingsTypes'>
        <div 
          className='manageCardBackArrow'
          onClick={() => {(functional.appSettingsChanged || functional.userSettingsChanged) ? setFunctional(prev => ({...prev, showPopUp: true})) : window.location.href = '/'}}
        >
            <ArrowBack />

        </div>
        <ButtonPrimary className='settingsSave' text='Save' onClick={() => handleSubmit()} /> 
        <ButtonPrimary className='settingsLogout' text='LogOut' onClick={() => handleLogOut()} />

        <h1>
          Settings
        </h1>

        <div className='settingsTypesButtons'>
          {settingTypes.map((type, index) => (
              <div 
                key={index}
                className={"settingsTypeButton" + (functional.settingType == type.label.toLocaleLowerCase() ? " active" : "")}
                onClick={() => setFunctional(prev => ({...prev, settingType: type.label.toLocaleLowerCase()}))}
              >
                {type.label}
              </div>
          ))}
        </div>

        
      </div>
      <div className='settingsOptions'>
        <h1>
          {settings[functional.settingType]?.title}
        </h1>

        <div className='settingGrid'> 
        {settings[functional.settingType]?.list.map((setting, index) => (
          <React.Fragment key={index}>
            <div className='settingText'>{setting.title}</div>
            
            {setting.input}
          </React.Fragment>
        ))}
      </div>
      </div>
    </div>
  )
}

export default Settings