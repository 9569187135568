import React from 'react'

const Overlay = ({
    children,
    show,
}) => {
  return (
    show && (
        <div className='overlay'>
            <div className='overlayChild'>
                {children}
            </div>
        </div>
    )
  )
}

export default Overlay