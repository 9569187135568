/*
    Purpose: Boolean sliding toggle button
    Author: Amos Gordon

    Latest Upadte: 6/24/2023
*/

import React, {useId} from 'react';
import styled from 'styled-components'

const StyledCheckbox = styled.input`
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
    pointer-events: none;
`

const StyledLabel = styled.label`
    --paddingX: 2px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    max-width: 10rem;
    min-width: 2rem;
    height: 100%;
    max-height: 1.5rem;
    aspect-ratio: 2 !important;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;

    & .sliderToggleButton {
        content: '';
        position: absolute;
        left: var(--paddingX);
        height: 90%;
        aspect-ratio: 1;
        border-radius: 50%;
        transition: 0.2s;
        background: #fff;
        box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
    }

    ${StyledCheckbox}:checked + & .sliderToggleButton {
        left: calc(100% - var(--paddingX));
        transform: translateX(-100%);
    }

    &:active .sliderToggleButton {
        transform: scaleX(105%);
    }

`

const SliderToggle = ({ 
    value, 
    name,
    setValue, 
    onColor,
}) => {
    const UID = `sliderToggle${useId()}`
    return (
    <>
        <StyledCheckbox
            checked={!!value}
            onChange={(e) => setValue(e)}
            name={name}
            id={UID}
            type="checkbox"
        />
        <StyledLabel
            style={{ background: !!value && onColor }}
            htmlFor={UID}
        >
            <span className={`sliderToggleButton`} />
        </StyledLabel>
    </>
    );
};

export default SliderToggle;