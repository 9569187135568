import React, { useState } from 'react'

import CallAPI from '../../components/functional/CallAPI'

import ButtonPrimary from '../../components/global/buttons/ButtonPrimary'
import DateInput from '../../components/global/inputs/placeholderAbove/DateInput'
import Input from '../../components/global/inputs/elementInset/Input'
import TextArea from '../../components/global/inputs/elementInset/TextArea'
import SliderToggle from '../../components/global/inputs/SliderToggle'
import { 
  ManageCard, 
  ManageCardBody, 
  ManageCardFooter, 
  ManageCardHeader 
} from '../../components/buildingBlocks/ManageCard'
import EntitySelector from '../../components/EntitySelector'

import JobsBackground from '../../images/jobs_bg.jpg'
import {
  CarOutline,
  Checkmark,
  DocumentTextOutline,
  LocationOutline,
  PersonOutline,
  CalendarOutline
} from 'react-ionicons'
import Overlay from '../../components/global/Overlay'
import { useNotification } from '../../components/global/Notifications'
import StateDropDown from '../EntityInfo/JobEntityBody/StateDropDown'


const steps = [
  {'icon': <DocumentTextOutline />, 'complete': false},
  {'icon': <LocationOutline />, 'complete': false},
  {'icon': <PersonOutline />, 'complete': false},
  {'icon': <CarOutline />, 'complete': false},
  {'icon': <Checkmark />, 'complete': false},
]


const Jobs = () => {
  const createNotification = useNotification()
  const [currentPage, setCurrentPage] = useState(0)
  const [formComplete, setFormComplete] = useState(false)
  const [showAddress, setShowAddress] = useState(true)

  const [form, setForm] = useState({
    customer: '',
    type: '', 
    description: '', 
    date: '',
    address: '',
    latitude: '',
    longitude: '',
    state: 'inactive',
  })

  const [users, setUsers] = useState([])
  const [vehicles, setVehicles] = useState([])

  const handleUpdate = (e) => {
    setForm(prev => ({...prev, [e.target.name]: e.target.value}))
  }

  const pages = [
    {
      "header": 'Job Info:',
      "body":
        <>
          <Input name='customer' placeholder='Customer Name' value={form.customer} setValue={handleUpdate} maxLength={35}/>
          <Input name='type' placeholder='Job Type' value={form.type} setValue={handleUpdate} maxLength={20}/>

          <TextArea name='description' placeholder='Description' value={form.description} setValue={handleUpdate} maxLength={200}/>

          <DateInput name='date' value={form.date} setValue={handleUpdate}/>
        </>,
        'requirements': [form.customer, form.date],
        'requirementFieldNames': ['Customer Name', 'Date']
    },

    {
      "header": 'Location:',
      "body":
        <>
          {showAddress ? (
            <Input name='address' placeholder='Address' value={form.address} setValue={handleUpdate} contentEditable={!(form.latitude || form.longitude)} maxLength={125}/>
          ) : (
            <>
            <Input name='latitude' placeholder='Latitude' value={form.latitude} setValue={handleUpdate} numbersOnly={true} contentEditable={!form.address} maxLength={9}/>
            <Input name='longitude' placeholder='Longitude' value={form.longitude} setValue={handleUpdate} numbersOnly={true} contentEditable={!form.address} maxLength={9}/>
            </>
          )}
          
          <ButtonPrimary text='Toggle Input' onClick={() => setShowAddress(!showAddress)} />
        </>,
    },

    {
      "header": 'Users:', 
      "body":
      <EntitySelector type={'users'} entityList={users} setEntityList={setUsers} key={'UserEntitySelector'}/>
    },

    {
      "header": 'Vehicles:', 
      "body":
      <EntitySelector type={'vehicles'} entityList={vehicles} setEntityList={setVehicles} key={'VehicleEntitySelector'}/>,
    },

    {
      "header": "Confirm:",
      "body": 
        <div className='manageConfirm'>

          <div className='jobsConfirmDate'>{form.date && (new Date(form.date)).toLocaleDateString()} <CalendarOutline/></div>

          <h2 className="jobsCustomerHeader">{form.customer} <b><i>{form.type}</i></b></h2> 

          {form.description}
          
          <div className='shadowDivider'><div className='shadow'></div></div>

          {form.address && <div><b>Address: </b> {form.address}</div>}
          {(form.latitude && form.longitude) && <><b>Coordinates: </b> {form.latitude + ' : ' + form.longitude}</>}

          <div className='listCol2'>
            <figure>
              <figcaption><b>Users: </b> </figcaption>
              <ul>
                {users.map((user, index) => 
                  <li key={index}>{user.name}</li>
                )}
            </ul>
            </figure>
            <figure>
              <figcaption><b>Vehicles: </b></figcaption>
              <ul>
                {vehicles.map((vehicle, index) => 
                  <li key={index}>{vehicle.name}</li>)}
              </ul>
            </figure>
          </div>
          
          <h4>State</h4>
          <div className='jobsStateDropDown'>
            <StateDropDown jobState={form.state} setJobState={(value) => setForm(prev => ({...prev, state: value}))}/>
          </div>
        </div>,
    }
  ]

  const handleSubmit = () => {
    CallAPI('/api/v1/job', 'POST', {
      ...form,
      coords: (form.latitude && form.longitude) && (form.latitude + ':' + form.longitude),
      users: users.map(user => user.id).join(','),
      vehicles: vehicles.map(vehicle => vehicle.id).join(','),
    })
    .then(result => {
      if (result.error) {
        createNotification({
          type: 'danger',
          message: result.error
        })
      } else {
        setFormComplete(true)
      }
    })
  }

  return (
    <>
    <Overlay show={formComplete}>
      <div className='overlayTitle'>
        Success
      </div>

      <div className='overlayButtonGroup'>
        <ButtonPrimary text='Make Another' onClick={() => window.location.href = '/management/jobs'}/>
        <ButtonPrimary text='Done' onClick={() => window.location.href = '/'} />        
      </div>
    </Overlay>
    
    <img className='manageBackground' src={JobsBackground} alt='Background' />

    <div className='manageContainer'>      
      <ManageCard>
        <ManageCardHeader>
          {pages[currentPage]?.header}
        </ManageCardHeader>

        <ManageCardBody>
          {pages[currentPage]?.body}
        </ManageCardBody>

        <ManageCardFooter 
          pages={pages}
          steps={steps}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handleSubmit={handleSubmit}
        />
      </ManageCard>
    </div>
    </>
  )
}

export default Jobs