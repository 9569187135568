import React, { useState, useContext, useEffect } from 'react'

import Input from '../components/global/inputs/placeholderAbove/Input'
import ButtonPrimary from '../components/global/buttons/ButtonPrimary'
import AuthContext from '../components/functional/AuthContext'

import customer_logo from '../images/customer_logo.jpeg'
import CallAPI from '../components/functional/CallAPI'

const LoginPage = () => {
  const [form, setForm] = useState({
    username: '',
    password: '',
    newPassword: '',
    confirmNewPassword: '',
    tempToken: '',
  })

  const [error, setError] = useState('')
  const [newLogIn, setNewLogIn] = useState(false)

  const { logIn, newUserLogIn } = useContext(AuthContext)

  const submit = () => {
    if (form.username === '' || form.password === '') {
      setError('Please enter a username and password.')
      return
    }

    logIn(form.username, form.password)
    .then(result => {
      if (result.error !== undefined) {
        setError(result.error)
        return
      }

      if (result.new && result.new == 1) {
        setForm(prev => ({...prev, tempToken: result.token}))
        setNewLogIn(true)
      }

      setError('')
      return
    })
  }

  const submitNewPassword = () => {
    if (!(form.newPassword.trim() === form.confirmNewPassword.trim())) {
      return setError('Passwords do not match.')
    }

    CallAPI(
      '/api/v1/user/password', 
      'PATCH', 
      {
        old_password: form.password,
        new_password: form.newPassword.trim(),
      },
      {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${form.tempToken}`
      },
    )
    .then(result => {
      if (result.error !== undefined) return setError(result.error)
      newUserLogIn(form.tempToken)
    })
  }

  const handleUpdate = (e) => {
    setForm(prev => ({...prev, [e.target.name]: e.target.value}))
  }
  
  useEffect(() => {
    error !== '' && setError('')
  }, [form.username, form.password])

  return (
    <div className='fullScrollableContainer'>
      <div className='loginContainer'>
        <div>
          <img src={customer_logo} alt='Company Logo'></img>
        </div>
        <div className='loginForm'>
          <h1>{!newLogIn ? "Log In" : "Change Password"}</h1>
          {!newLogIn ? (
            <>
            <Input 
              name='username' 
              className='loginInput' 
              placeholder="Username" 
              value={form.username} 
              setValue={handleUpdate} 
              onEnter={() => submit()} 
              key={'input1'}
            />

            <Input 
              name='password' 
              placeholder="Password" 
              value={form.password} 
              setValue={handleUpdate} 
              type="password" 
              onEnter={() => submit()}  
              key={'input2'}
            />

            {error !== '' && (
              <div className='errorMsg'>
                {error}
              </div>
            )}

            <ButtonPrimary className='mt-2' text='Log In' onClick={() => submit()} />
            </>
          ) :
          (
            <>
            <Input 
              name='newPassword'
              placeholder='New Password'
              value={form.newPassword}
              setValue={handleUpdate}
              type='password'
              onEnter={() => submitNewPassword()}
              maxLength={64}
              key={'input3'}
            />

            <Input 
              name='confirmNewPassword'
              placeholder='Confirm Password'
              value={form.confirmNewPassword}
              setValue={handleUpdate}
              type='password'
              onEnter={() => submitNewPassword()}
              maxLength={64}
              key={'input4'}
            />

            {error !== '' && (
              <div className='errorMsg'>
                {error}
              </div>
            )}  

            <ButtonPrimary text='Confirm' className='mt-2' onClick={() => submitNewPassword()} />

            </>
          )}
          
        </div>
      </div>
    </div>
  )
}

export default LoginPage