import React, {useState, useEffect} from "react"

import TokenData from "../../../components/functional/TokenData"
import CallAPI from '../../../components/functional/CallAPI'


import TextArea from '../../../components/global/inputs/placeholderAbove/TextArea'
import ButtonPrimary from '../../../components/global/buttons/ButtonPrimary'
import ContextMenuElement from '../../../components/global/ContextMenu'

import { BeatLoader } from "react-spinners"
import { 
    CloseOutline,
} from 'react-ionicons'
import { useNotification } from "../../../components/global/Notifications"

const Notes = ({ id }) => {
    const createNotification = useNotification()

    const [note, setNote] = useState('')
    const [notesList, setNotesList] = useState([])
    const [noteLoad, setNoteLoad] = useState(false)
    const [change, setChange] = useState(false)

    const today = new Date()

    const u_id = TokenData().u_id

    const processNotes = (noteList) => {
        const newList = noteList.reduce((acc, note) => {
            const date = note.date_time.split(' ')[0]

            if (!acc[date]) {
                acc[date] = []
            }

            acc[date].push(note)

            return acc
        }, {})

        for (const date in newList) {
            newList[date].sort((a, b) => {
                return new Date(b.date_time) - new Date(a.date_time)
            })
        }

        return Object.keys(newList)
        .sort((a, b) => new Date(b) - new Date(a))
        .reduce((acc, date) => {
            acc[date] = newList[date]
            return acc
        }, {})
    }

    useEffect(() => {
        CallAPI('/api/v1/note?id='+id)
        .then(result => {
            if (result.error) return (
                createNotification({
                    type: 'danger', 
                    message: <span><b>Notes Error:</b>{result.error}</span>
                })
            )
            
            setNotesList(processNotes(result))
        })

    }, [change])

    return (
    <>
        <h2 className='align-self-start'>Notes:</h2>
        <TextArea placeholder='New note here...' value={note} setValue={(e) => setNote(e.target.value)} maxLength={250}/>
        <ButtonPrimary text='Post Note' onClick={() => {
            if (note.length < 1) return (
                createNotification({
                    type: 'warn', 
                    message: <span><b>Notes Warning:</b>No text entered.</span>,
                    timeout: 3500,
                })
            )

            setNoteLoad(true)
            CallAPI('/api/v1/note', 'POST', {job_id: id, msg: note})
            .then(result => {
                setNoteLoad(false)
                if (result.error) return (
                    createNotification({
                        type: 'danger', 
                        message: <span><b>Notes Error:</b>{result.error}</span>,
                        timeout: 3500,
                    })
                )

                setChange(!change)
                setNote('')
            })
        }}/>

        <div className='entityViewJobDivider'></div>
        {noteLoad && <BeatLoader />}
        {Object.keys(notesList)?.map((date, index) => (
            <React.Fragment key={index}>
            <div className="mt-2">{(new Date(date.replaceAll('-', '/')).toDateString()) === today.toDateString() ? 'Today' : (new Date(date.replaceAll('-', '/'))).toDateString()}</div>
            
            {notesList[date]?.map((note, noteIndex) => (
                <div className='entityViewJobNote' key={noteIndex}>
                    <div className='entityViewJobNoteHeader'>
                        <ContextMenuElement 
                            options={{
                                title: note.name,
                                buttons: [
                                    {
                                        text: 'See profile', 
                                        onClick: () => {
                                            window.location.href='/entity_info?entity=user&id='+note.u_id
                                        }
                                    }
                                ]
                            }}
                        >
                            <div className="hover-pointer">{note.name}</div>
                        </ContextMenuElement>
                        {note.u_id === u_id && 
                            <div className="entityViewJobNoteHeaderX"
                                onClick={() => {
                                    CallAPI('/api/v1/note', 'DELETE', {note_id: note.note_id})
                                    .then(result => {
                                        setNoteLoad(false)
                                        if (result.error) return (
                                            createNotification({
                                                type: 'danger', 
                                                message: <span><b>Notes Error:</b>{result.error}</span>,
                                                timeout: 3500,
                                            })
                                        )

                                        setChange(!change)
                                    })
                                }}
                            ><CloseOutline /></div>   
                        }
                    </div>
                    <div className='entityViewJobNoteBody'>
                        {note.note}
                    </div>
                    <div className='entityViewJobNoteFooter'>
                        {(new Date(note.date_time.replaceAll('-', '/')+" UTC")).toLocaleTimeString([], {hour: 'numeric', minute: '2-digit'})}
                    </div>
                </div>
            ))}
            </React.Fragment>
        ))}
    </>
    )
}

export default Notes
