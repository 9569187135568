import React, { useEffect, useState } from 'react'
import TokenData from '../../components/functional/TokenData'

import JobEntityBody from './JobEntityBody/JobEntityBody'
import UserEntityBody from './UserEntityBody'
import VehicleEntityBody from './VehicleEntityBody'

import { 
    ArrowBack,
    CreateOutline,
    ImageOutline,
    ReaderOutline,
} from 'react-ionicons'
import ButtonPrimary from '../../components/global/buttons/ButtonPrimary'
import PopUp from '../../components/global/PopUp'
import CallAPI from '../../components/functional/CallAPI'
import StateDropDown from './JobEntityBody/StateDropDown'
import { useNotification } from '../../components/global/Notifications'
import ErrorPage from '../../404'

const iconHeight = '1.75rem'

const userRole = TokenData()?.role

const EntityInfo = () => {
    const createNotification = useNotification()
    const urlParams = new URLSearchParams(window.location.search)
    const type = urlParams.get('entity')
    const id = urlParams.get('id')

    const [state, setState] = useState({
        editing: false,
        showPopUp: false,
        formChanged: false,
        showDeletePopUp: false,
        showSubmitPopUp: false,
        finished: false,
    })

    const [notesOpen, setNotesOpen] = useState(false)
    const [picsOpen, setPicsOpen] = useState(false)
    const [jobState, setJobState] = useState('active')

    const updateState = (field, value) => {
        setState(prev => ({...prev, [field]: value}))
    }


    const handleDelete = () => {
        CallAPI("/api/v1/"+type, "DELETE", { id: id })
        .then(data => {
            if (data.error !== undefined) return (
                createNotification({
                    type: 'danger', 
                    message: <span><b>Could not delete {type}:</b>{data.error}</span>,
                    timeout: 3500,
                })
            )
            
            window.location.href = '/'
        })
    }

    useEffect(() => {
        if (state.finished && state.formChanged) {
            updateState('showSubmitPopUp', true)
        }
    }, [state.finished])

    
    const SubmitPopUp = ({handleSubmit}) => {
        return (
            <PopUp 
                title={'Would you like to submit these changes?'}
                buttons={[
                    <ButtonPrimary text='Yes' onClick={() => {handleSubmit()}} />,
                    <ButtonPrimary text='No' className='bg-danger' onClick={() => {window.location.reload()}} />,
                    <ButtonPrimary 
                        text='Cancel' 
                        className='bg-grey' 
                        onClick={() => {
                            updateState('showSubmitPopUp', false)
                            updateState('finished', false)
                        }} 
                    />

                ]}
                showPopUp={state.showSubmitPopUp}
                setShowPopup={() => {updateState('showSubmitPopUp', false); updateState('finished', false)}}
            /> 
        )
    }

    return (
        (type && id) ? (
            <div className='entityViewContainer'>
                <PopUp 
                    title={`Would you like to delete this ${type}?`}
                    buttons={[
                        <ButtonPrimary text='Yes' onClick={() => {handleDelete()}} />,
                        <ButtonPrimary text='No' className='bg-danger' onClick={() => {updateState("showDeletePopUp", false)}} />
                    ]}
                    showPopUp={state.showDeletePopUp}
                    setShowPopup={() => {updateState("showDeletePopUp", false)}}
                /> 

                <div className='entityViewTitleBar'>
                    <div 
                        onClick={() => {window.location.href = '/'}}
                    >
                        <ArrowBack height={iconHeight} width={iconHeight} />
                    </div>
        
                    <div className='entityViewTitleButtons'>
                        {type === 'job' && <>
                            <ReaderOutline className="entityViewTitleButton" onClick={() => {setNotesOpen(!notesOpen); setPicsOpen(false)}} />

                            <ImageOutline className="entityViewTitleButton" onClick={() => {setPicsOpen(!picsOpen); setNotesOpen(false)}} />

                            <StateDropDown id={id} jobState={jobState} setJobState={setJobState} />
                        </>}


                        {userRole === 'manager' &&
                        <>
                            <div className='entityViewTitleButtonsDivider'></div>
                            {state.editing && 
                                <ButtonPrimary text='Delete' className='bg-danger' onClick={() => {updateState("showDeletePopUp", true)}} />
                            }

                            {!state.editing ? (
                                <CreateOutline 
                                    className="entityViewTitleButton" 
                                    onClick={() => {
                                        updateState("editing", true)
                                    }}    
                                /> 
                            )  : (
                                <ButtonPrimary text='Done' onClick={() => {state.formChanged ? updateState("finished", true) : updateState("editing", false)}} />
                            )}
                        </>
                        }
                    </div>
                </div>
                <div className='entityViewBody'>
                    {type === 'job' ? (
                        <JobEntityBody 
                            id={id}
                            notesOpen={notesOpen} 
                            setNotesOpen={setNotesOpen} 
                            picsOpen={picsOpen} 
                            setPicsOpen={setPicsOpen} 
                            state={state}
                            updateState={updateState}
                            setJobState={setJobState}
                            SubmitPopUp={SubmitPopUp}
                        /> 
                    ) : (
                        <div className='entityViewCardContainer'>
                            {type === 'user' ? (
                                <UserEntityBody id={id} state={state} updateState={updateState} SubmitPopUp={SubmitPopUp}/>
                            ) : (
                                type === 'vehicle' && (
                                    <VehicleEntityBody id={id} state={state} updateState={updateState} SubmitPopUp={SubmitPopUp}/>
                                )
                            )}
                        </div>
                    )
                    }
                </div>
            </div>
        ) : (
            <ErrorPage />
        )
    )
}

export default EntityInfo