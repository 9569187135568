/*
    Purpose: Provide a default layout for cards in the managment pages
    Author: Amos Gordon

    Latest Upadte: 6/24/2023
*/

import React from 'react'

import ButtonPrimary from '../../components/global/buttons/ButtonPrimary'

import {
    ArrowBack,
} from 'react-ionicons'
import { useNotification } from '../global/Notifications'


const requirementsFulfilled = (requirements) => {
    if (!requirements) return true

    let fullfilled = true

    requirements.forEach(requirement => {
        if (typeof requirement === 'object') {
            requirement.length < 1 && (fullfilled = false)
        } else {
            !requirement && (fullfilled = false)
        }
    });

    return fullfilled
}

export const ManageCard = ({children, backArrow=true}) => {
  return (
    <div className='manageCard'>
        {backArrow && (
            <div 
              className='manageCardBackArrow'
              onClick={() => {window.location.href = '/'}}
            >
                <ArrowBack/>
            </div>
        )}
        {children}
    </div>
  )
}


export const ManageCardHeader = ({children}) => {
    return (
        <div className='manageCardHeader'>
            <h3>{children}</h3>
        </div>
    )
}


export const ManageCardBody = ({children}) => {
    return (
        <div className='manageCardBody'>
            {children}
        </div>
    )
}


export const ManageCardFooter = ({
    pages,
    steps,
    currentPage,
    setCurrentPage,
    handleSubmit,
    nextFunction= async () => {return true}
}) => {
    const createNotification = useNotification()

    return (
        <div className='manageCardFooter'>
            <div className='manageButtonsContainer'>
                <ButtonPrimary 
                    text='Previous' 
                    className={currentPage === 0 ? 'd-none' : ''} 
                    onClick={() => {
                        if (!requirementsFulfilled(pages[currentPage]?.requirements)) {
                            steps[currentPage].complete = false
                        }

                        currentPage !== 0 && setCurrentPage(currentPage-1)
                    }}
                />

                <ButtonPrimary 
                    text='Next' 
                    className={currentPage === (steps.length-1) ? 'd-none' : ''} 
                    onClick={async () => {
                        if (!requirementsFulfilled(pages[currentPage]?.requirements)) {
                            steps[currentPage].complete = false
                            createNotification({
                                type: 'warn', 
                                message: 
                                    <span>
                                        <b>Please fill out required fields:</b>
                                        <span>
                                            {pages[currentPage].requirementFieldNames?.map((requiredField, index) => 
                                                !pages[currentPage].requirements[index] && <div key={index}>{requiredField}</div>
                                            )}
                                        </span>
                                    </span>
                            })
                            return
                        } else {
                            steps[currentPage].complete = true
                        }

                        const nextFuncResult = await nextFunction() 

                        if (!nextFuncResult) return

                        currentPage !== (steps.length-1) && setCurrentPage(currentPage+1)
                    }}
                />

                <ButtonPrimary 
                    text='Submit' 
                    className={currentPage !== (steps.length-1) ? 'd-none' : ''} 
                    onClick={() => handleSubmit()}
                />
            </div>

            <div className='manageStepsContainer'>
                {steps.map((step, stepIndex) => (
                    <div 
                        className={'cardStep' + (stepIndex === currentPage ? ' active' : '') + (steps[stepIndex].complete ? ' finish' : '')} 
                        key={stepIndex}
                        onClick={async () => {
                            if (!requirementsFulfilled(pages[currentPage]?.requirements)) {
                                steps[currentPage].complete = false
                                if (stepIndex > currentPage) {
                                    createNotification({
                                        type: 'warn', 
                                        message: 
                                            <span>
                                                <b>Please fill out required fields:</b>
                                                <span>
                                                    {pages[currentPage].requirementFieldNames?.map((requiredField, index) => 
                                                        !pages[currentPage].requirements[index] && <div key={index}>{requiredField}</div>
                                                    )}
                                                </span>
                                            </span>
                                    })

                                    return
                                }
                            } else {
                                steps[currentPage].complete = true
                            }

                            const nextFuncResult = (stepIndex > currentPage) ? await nextFunction() : true

                            if (!nextFuncResult) return

                            let allComplete = true
                            for (let i = stepIndex-1; i >= 0; i--) {
                                requirementsFulfilled(pages[i]?.requirements) && (steps[i].complete = true)

                                if (!(steps[i].complete)) {
                                    allComplete = false
                                }
                            }

                            if (!allComplete) return

                            setCurrentPage(stepIndex)
                        }}
                    >
                        {step.icon}
                    </div>
                ))}
            </div>
        </div>
    )
}
