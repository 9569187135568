import React, {useState, useEffect} from "react"

import CallAPI from '../../components/functional/CallAPI'
import Input from "../../components/global/inputs/elementInset/Input"
import TextArea from "../../components/global/inputs/elementInset/TextArea"

import SplashScreen from '../../pages/SplashScreen'
import { useNotification } from "../../components/global/Notifications"
import DefaultPic from '../../images/default_truck.png'

const VehicleEntityBody = ({ id, state, updateState, SubmitPopUp }) => {
    const createNotification = useNotification()

    const [form, setForm] = useState({
        name: '',
        license: '', 
        vin: '',
        description: '',
    })
    const [loading, setLoading] = useState(true)

    const handleUpdate = (e) => {
        setForm(prev => ({...prev, [e.target.name]: e.target.value}))
        updateState("formChanged", true)
    }

    const updateForm = (key, value) => {
        setForm(prev => ({...prev, [key]: value}))
    }

    const handleSubmit = async () => {
        return CallAPI('/api/v1/vehicle', 'PATCH', {
            ...form,
            id: id,
        })
        .then(result => {
            if (result.error) return (
                createNotification({
                    type: 'danger',
                    message: <span><b>Submission Error:</b>{result.error}</span>,
                })
            )

            window.location.reload()
        })
    }

    useEffect(() => {
        setLoading(true)
        CallAPI('/api/v1/vehicle?vic_id='+id)
        .then(result => {
            setLoading(false)
            if (result.error) return (
                createNotification({
                    type: 'danger', 
                    message: result.error,
                })
            )

            result = result[0]

            updateForm('name', result.name)
            updateForm('license', result.license)
            updateForm('vin', result.vin)
            updateForm('description', result.description)
        })
    }, [])

    return (
        !loading ? (<>
            {state.showSubmitPopUp && <SubmitPopUp handleSubmit={handleSubmit}/>}

            <div className='entityViewCard'>
                <div>
                    <div className='profilePic entityViewCardPic'>
                        <img 
                            src={DefaultPic}
                            alt='Profile Pic'
                        />
                    </div>
                    <div className='entityViewCardName w-100 pe-1'>
                        <h2>{!state.editing ? form.name : <Input name='name' placeholder="Name" value={form.name} setValue={handleUpdate} maxLength={30} />}</h2>
                    </div>
                </div>
                <div>
                    <div className='entityViewCardRightContainer w-100 ps-1'>
                        <h3>{!state.editing ? form.license : <Input name='license' placeholder="Licesnce" value={form.license} setValue={handleUpdate} maxLength={10} />}</h3>
                        <h3>{!state.editing ? form.vin : <Input name='vin' placeholder="VIN" value={form.vin} setValue={handleUpdate} maxLength={17} />}</h3>
                        <p>{!state.editing ? form.description : <TextArea name='description' placeholder="Description" value={form.description} setValue={handleUpdate} maxLength={30} />}</p>
                    </div>
                </div>
            </div>
        </>) : (
            <SplashScreen />
        )
    )
}

export default VehicleEntityBody