import React, { useState } from "react"
import { ChevronBack, ChevronForward } from 'react-ionicons'


const Carousel = ({ 
  children, 
  expand=false, 
  controlButtons=[],
  setIndex=() => {}
}) => {
    const [carouselCounter, setCarouselCounter] = useState(-2)
    const carouselLength = children.length-3
    
  
    const getPos = (index) => {
      const pos = index - carouselCounter
      if (pos < 1 || pos > 3 ) return 'hidden'
  
      return pos.toString()
    }
  
    const handleCycle = (e) => {
      const direction = e.target.dataset.direction
      
      if (direction === 'back' && carouselCounter > -2) {
        setIndex(carouselCounter+1)
        return setCarouselCounter(carouselCounter-1)
      }
  
      if (direction === 'forward' && carouselLength > carouselCounter) {
        setIndex(carouselCounter+3)
        return setCarouselCounter(carouselCounter+1)
      }
    }
  
    return (
      <>
      <div className={'carouselContainer' + (expand ? ' expand' : '')}>
        {children.map((child, index) => (
          <div className={'carouselCard'  + (expand ? ' expand' : '')} data-pos={getPos(index)} key={index}>
            {child}
          </div>
        ))}
      </div>
      <div className={'carouselButtonContainer' + (expand ? ' expand' : '')}>
        <div 
          className={'carouselButton' + (carouselCounter > -2 ? '' : ' opacity-0')}
          data-direction='back' 
          onClick={(e) => handleCycle(e)}
        >
          <ChevronBack />
        </div>

        {controlButtons?.map((button, index) => (
          <React.Fragment key={index}>
            {button}
          </React.Fragment>
        ))}
  
        <div 
          className={'carouselButton' + (carouselLength > carouselCounter ? '' : ' opacity-0')}
          data-direction='forward' onClick={(e) => handleCycle(e)}
        >
          <ChevronForward />
        </div>
      </div>
      </>
    )
  }
  
  export default Carousel