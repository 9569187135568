import React, {useState, useEffect} from "react"

import CallAPI from '../../../components/functional/CallAPI'

import SplashScreen from '../../SplashScreen'
import Notes from "./Notes"
import Pictures from "./Pictures"
import Map from './Map'

import TextArea from '../../../components/global/inputs/elementInset/TextArea'
import ButtonPrimary from '../../../components/global/buttons/ButtonPrimary'
import ContextMenuElement from '../../../components/global/ContextMenu'
import Input from '../../../components/global/inputs/elementInset/Input'
import DateInput from '../../../components/global/inputs/placeholderAbove/DateInput'

import { 
    CloseOutline,
    CreateOutline,
    PeopleOutline,
    CarOutline,
    CalendarOutline,
} from 'react-ionicons'
import SlidingSidebar from "../../../components/global/SlidingSidebar"
import { useNotification } from "../../../components/global/Notifications"
import TokenData from "../../../components/functional/TokenData"
import EntitySelector from "../../../components/EntitySelector"
import Overlay from "../../../components/global/Overlay"

const userRole = TokenData()?.role

const JobEntityBody = ({ 
    id, 
    notesOpen, 
    setNotesOpen, 
    picsOpen, 
    setPicsOpen,
    state, 
    updateState,
    setJobState,
    SubmitPopUp
}) => {
    const createNotification = useNotification() 

    const [data, setData] = useState(null)

    const [emOverlayOpen, setEmOverlayOpen] = useState(false)
    const [vicOverlayOpen, setVicOverlayOpen] = useState(false)

    const [form, setForm] = useState({
        customer: '',
        type: '', 
        date: '', 
        description: '', 
        address: '',
        longitude: '',
        latitude: '', 
    })

    const [users, setUsers] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [showAddress, setShowAddress] = useState(true)

    const handleUpdate = (e) => {
        setForm(prev => ({...prev, [e.target.name]: e.target.value}))
        updateState("formChanged", true)
    }

    const updateForm = (key, value) => {
        value && setForm(prev => ({...prev, [key]: value}))
    }

    const handleSubmit = async () => {
        const {latitude, longitude, ...formData} = form;
        
        return CallAPI('/api/v1/job', 'PATCH', {
            ...formData,
            id: id,
            coords: (form.latitude && form.longitude) ? (form.latitude + ':' + form.longitude) : '',
            users: users.map(user => (user.id)),
            vehicles: vehicles.map(vehicle => (vehicle.id)),
        }).then(result => {
            if (result.error) return (
                createNotification({
                    type: 'danger', 
                    message: <span><b>Submission Error:</b>{result.error}</span>,
                })
            )

            window.location.reload()
        })
    }

    useEffect(() => {
        CallAPI('/api/v1/job?id='+id)
        .then(result => {
            if (result.error) return (
                createNotification({
                    type: 'danger',
                    message: result.error,
                })
            )

            setData(result)
            setJobState(result.state)
            result.users && setUsers(result.users.map(({ name, u_id }) => ({ name, id: u_id })))
            result.vehicles && setVehicles(result.vehicles.map(({ vic_name, vic_id }) => ({ name: vic_name, id: vic_id })))
        })
    }, [])

    useEffect(() => {
        if (data && state.editing && userRole === 'manager') {
            updateForm('customer', data.customer)
            updateForm('type', data.type)
            updateForm('date', data.date?.split('T')[0])
            updateForm('description', data.description)
            
            if (data.address) {
                updateForm('address', data.address)
            } else if (data.coords) {
                setShowAddress(false)
                updateForm('latitude', data.coords?.split(':')[0])
                updateForm('longitude', data.coords?.split(':')[1])
            }
        }

    }, [state.editing])

    return (
    data ? (<>
        <SubmitPopUp handleSubmit={handleSubmit}/>

        <div className='entityViewJobBody'>
            <div className='center-child-y p-2 flex-1' onClick={() => {setNotesOpen(false); setPicsOpen(false)}} >
                <Overlay show={emOverlayOpen}>
                    <CloseOutline 
                        className='hover-pointer w-fit mb-1'
                        onClick={() => {
                            setEmOverlayOpen(false)
                        }}
                    />
                    <EntitySelector type={'users'} entityList={users} setEntityList={setUsers} />
                </Overlay>

                <Overlay show={vicOverlayOpen}>
                    <CloseOutline 
                        className='hover-pointer w-fit mb-1'
                        onClick={() => {
                            setVicOverlayOpen(false)
                        }}
                    />
                    <EntitySelector type={'vehicles'} entityList={vehicles} setEntityList={setVehicles} />
                </Overlay>

                <h2 className='mb-1'>{state.editing ? <Input name='customer' placeholder='Customer' value={form.customer} setValue={handleUpdate} maxLength={35}/> : data.customer}</h2>
                <i>{state.editing ? <Input name='type' placeholder='Type' value={form.type} setValue={handleUpdate} maxLength={20} /> : data.type}</i>

                <div className='entityViewJobDivider'></div>

                <div className='entityViewJobDate'>
                    <CalendarOutline />
                    {state.editing ? <DateInput name='date' value={form.date} setValue={handleUpdate}/> : (data.date ? (new Date(data.date.replaceAll("-", "/")).toDateString()) : 'No date selected.')}
                </div>

                <div className='entityViewJobItem'>
                    {state.editing ? <TextArea name='description' placeholder='Description' value={form.description} setValue={handleUpdate} maxLength={200}/> : data.description}
                </div>
                
                <div className='entityViewJobItem'>
                    {state.editing ? (
                    <div className='center-child-y'>
                        {showAddress ? (
                            <Input name='address' placeholder='Address' value={form.address} setValue={handleUpdate} contentEditable={!(form.latitude || form.longitude)} maxLength={125}/>
                        ) : (
                            <>
                            <Input name='latitude' placeholder='Latitude' value={form.latitude} setValue={handleUpdate} numbersOnly={true} contentEditable={!form.address} maxLength={9}/>
                            <Input name='longitude' placeholder='Longitude' value={form.longitude} setValue={handleUpdate} numbersOnly={true} contentEditable={!form.address} maxLength={9}/>
                            </>
                        )}
                        
                        <ButtonPrimary 
                            className="mt-2" 
                            text='Toggle Input' 
                            onClick={() => {
                                showAddress && setForm(prev => ({...prev, address: ''}))
                                !showAddress && setForm(prev => ({...prev, latitude: '', longitude: ''}))
                                setShowAddress(!showAddress)
                            }} />
                    </div>
                    ): (!data.address ? data.coords : data.address)}
                </div>

                <div className='entityViewJobAsigned'>
                <div>
                    <div className='centerChildX'>
                        {userRole === 'manager' ? (
                            <>
                            <ContextMenuElement 
                                options={{
                                    title: 'Users',
                                    buttons: [
                                        {
                                            text: 'Edit', 
                                            onClick: () => {
                                                setEmOverlayOpen(true)
                                                updateState("editing", true)
                                                updateState("formChanged", true)
                                            }
                                        }
                                    ]
                                }}
                            >
                                <div className='hover-pointer'>
                                    <div style={{pointerEvents: 'none'}}><PeopleOutline width={'3rem'} height={'3rem'} /></div>
                                </div>
                            </ContextMenuElement>
                            {state.editing &&
                                <CreateOutline 
                                    className='hover-pointer' 
                                    onClick={() => {
                                        setEmOverlayOpen(true)
                                        updateState("formChanged", true)
                                    }}
                                />
                            }
                            </>
                        ) : (
                            <div style={{pointerEvents: 'none'}}><PeopleOutline width={'3rem'} height={'3rem'} /></div>
                        )}                        
                    </div>
                    <ul>
                        {users?.map((user, userIndex) => (
                            <ContextMenuElement 
                                key={userIndex}
                                options={{
                                    title: user.name,
                                    buttons: [
                                        {
                                            text: 'See profile', 
                                            onClick: () => {
                                                window.location.href='/entity_info?entity=user&id='+user.id
                                            }
                                        }
                                    ]
                                }}
                            >
                                <li className='hover-pointer'>{user.name}</li>
                            </ContextMenuElement>
                        ))}
                    </ul>
                </div>

                <div>
                    <div className='centerChildX'>
                        {userRole === 'manager' ? (<>
                            <ContextMenuElement 
                                options={{
                                    title: 'Vehicles',
                                    buttons: [
                                        {
                                            text: 'Edit', 
                                            onClick: () => {
                                                setVicOverlayOpen(true)
                                                updateState("editing", true)
                                                updateState("formChanged", true)
                                            }
                                        }
                                    ]
                                }}
                            >
                                    <div className='hover-pointer'>
                                        <div style={{pointerEvents: 'none'}}><CarOutline width={'3rem'} height={'3rem'} /></div>
                                    </div>
                            </ContextMenuElement>

                            {state.editing && 
                                <CreateOutline 
                                    className='hover-pointer' 
                                    onClick={() => {
                                        setVicOverlayOpen(true)
                                        updateState("formChanged", true)
                                    }}
                                />
                            }
                        </>) : (
                            <div style={{pointerEvents: 'none'}}><CarOutline width={'3rem'} height={'3rem'} /></div>
                        )}
                        
                    </div>
                    <ul>
                        {vehicles?.map((vehicle, vehicleIndex) => (
                            <ContextMenuElement 
                            key={vehicleIndex}
                            options={{
                                title: vehicle.name,
                                buttons: [
                                    {
                                        text: 'See profile', 
                                        onClick: () => {
                                            window.location.href='/entity_info?entity=vehicle&id='+vehicle.id
                                        }
                                    }
                                ]
                            }}
                        >
                            <li className='hover-pointer'>{vehicle.name}</li>
                        </ContextMenuElement>
                        ))}
                    </ul>
                </div>
            </div>

                {/* TODO remove when ready for map || <Map />*/}
            </div>

            <SlidingSidebar open={notesOpen}>
                <Notes id={id} />
            </SlidingSidebar>
            
            <SlidingSidebar open={picsOpen}>
                <Pictures id={id} />
            </SlidingSidebar>
        </div>

    </>) : (
        <SplashScreen />
    ))
}

export default JobEntityBody