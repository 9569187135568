import React, { useEffect, useState } from 'react'

import CallAPI from '../functional/CallAPI'

import ButtonPrimary from '../global/buttons/ButtonPrimary'
import {HashLoader} from 'react-spinners'
import { useNotification } from '../global/Notifications'
import ContextMenuElement from '../global/ContextMenu'


const jobStateInfo = {
    'inactive': {
        colorIndicator: 'var(--danger)',
        term: 'Inactive'
    },
    'active': {
        colorIndicator: 'var(--neutral)',
        term: 'In Progress',
    },
    'pending_review': {
        colorIndicator: 'var(--warn)',
        term: 'Pending Review',
    },
    'complete': {
        colorIndicator: 'var(--success)',
        term: 'Complete',
    }
}

const TodaysJobs = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [jobsList, setJobsList] = useState([])
    const [defaultText, setDefaultText] = useState('No Jobs Today')

    const createNotification = useNotification()

    const date = (new Date()).toLocaleDateString()

    const handleSubmitState = (id, state) => {
        if (!id || !state) return

        CallAPI('/api/v1/job/state', 'PATCH', {
            id: id,
            state: state,
        })
        .then(result => {
            if (result.error !== undefined) return (
                createNotification({
                    type: 'danger',
                    message: <span><b>Could not change state:</b>{result.error}</span>,
                    timeout: 3500,
                })
            )
            
            setJobsList(prev => (prev.map(job => {
                if (job.jobID === id) return {
                    ...job,
                    state: state,
                }
                return job
            })))
        })
    }


    useEffect(() => {
        setDefaultText('No Jobs Today')
        CallAPI('/api/v1/job?date='+date)
        .then(data => {
            if (data.error) {
                createNotification({
                    type: 'danger',
                    message: <span><b>Todays Jobs:</b>{data.error}</span>,
                    timeout: 2500,
                })

                setDefaultText('Could not load.')
                return 
            }

            setIsLoading(false)
            setJobsList([
                ...data.filter(job => job.state === 'inactive'),
                ...data.filter(job => job.state === 'active'),
                ...data.filter(job => job.state === 'pending_review'),
                ...data.filter(job => job.state === 'complete'),
            ])
        })
    }, [])

    return (
        <div className='widgetLg'>
        {isLoading ? (
            <div className='dailyJobsNone centerChildX' style={{padding: "10px"}}>
                <HashLoader/>
            </div>
        ) : (
            jobsList.length > 0 ? (
                <>
                <h2 className='dailyJobsTitle'>Today's Jobs</h2>
                <div className='dailyJobsContainer'>
                    {jobsList.map((job, index) =>
                        <ContextMenuElement 
                            key={index}
                            options={{
                                title: 'Change State',
                                buttons: Object.keys(jobStateInfo).filter(state => state !== job.state).map(key => ({
                                    text: <span style={{color: jobStateInfo[key].colorIndicator}}>{jobStateInfo[key].term}</span>, 
                                    onClick: () => {handleSubmitState(job.jobID, key)}
                                }))
                            }}
                        >
                            <div className='dailyJobsCard' style={{'--bannerColor': jobStateInfo[job.state].colorIndicator}}>
                                <div className='dailyJobsHeader'>
                                    <b>{job.customer}</b>
                                    <i>{job.type}</i>
                                </div>
                                <div className='dailyJobsBody'>
                                    <span>{job.description}</span>
                                    
                                    <ButtonPrimary text='View' onClick={() => {
                                        window.location.href = '/entity_info?entity=job&id='+job.jobID
                                    }}/>
                                </div>
                            </div>
                        </ContextMenuElement>
                    )}
                </div>
                </>
            ) : (
                <div className='dailyJobsNone'>
                    <h2>{defaultText}</h2>
                </div>
            )
        )}
        </div>
    )
}

export default TodaysJobs