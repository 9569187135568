import React, { useState } from 'react'

import CallAPI from '../../components/functional/CallAPI'

import ButtonPrimary from '../../components/global/buttons/ButtonPrimary'
import Input from '../../components/global/inputs/elementInset/Input'
import DropDown, {DropDownItem} from '../../components/global/inputs/DropDown'
import SliderToggle from '../../components/global/inputs/SliderToggle'
import { 
  ManageCard, 
  ManageCardBody, 
  ManageCardFooter, 
  ManageCardHeader
} from '../../components/buildingBlocks/ManageCard'

import UsersBackground from '../../images/users_bg.jpg'
import {
  TextOutline,
  CallOutline,
  KeyOutline,
  Checkmark,
} from 'react-ionicons'
import Overlay from '../../components/global/Overlay'
import { useNotification } from '../../components/global/Notifications'

const steps = [
  {'icon': <TextOutline />, 'complete': false},
  {'icon': <CallOutline />, 'complete': false},
  {'icon': <KeyOutline />, 'complete': false},
  {'icon': <Checkmark />, 'complete': false},
]

const Users = () => {
  const createNotification = useNotification()
  const [currentPage, setCurrentPage] = useState(0)
  const [formComplete, setFormComplete] = useState(false)

  const [form, setForm] = useState({
    first: '',
    last: '',
    username: '',
    useDefault: true,
    defaultUsername: '',
    phone: '', 
    role: 'user',
  })

  const handleUpdate = (e) => {
    setForm(prev => ({...prev, [e.target.name]: e.target.value}))
  }

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const pages = [
    {
      "header": 'Name:',
      "body":
        <>
          <Input name='first' placeholder='First' value={form.first} 
            setValue={(e) => {
              setForm(prev => ({
                ...prev,
                first: e.target.value,
                defaultUsername: (e.target.value.charAt(0) + prev.last).toLowerCase()
              }))
            }} 
            maxLength={20}
          />
          <Input name='last' placeholder='Last' value={form.last} 
            setValue={(e) => {
              setForm(prev => ({
                ...prev,
                last: e.target.value,
                defaultUsername: (prev.first.charAt(0) + e.target.value).toLowerCase()
              }))
            }} 
            maxLength={20}
          />
          
          <Input name={form.useDefault ? 'defaultUsername' : 'username'} placeholder='Username' 
            value={form.useDefault ? form.defaultUsername : form.username} 
            setValue={handleUpdate} 
            contentEditable={form.useDefault ? false : true} 
            maxLength={40}
          />
          <div className='manageUsersNameToggle'>
            <span>Use default username</span><SliderToggle name='useDefault' value={form.useDefault} setValue={() => setForm(prev => ({...prev, useDefault: !prev.useDefault}))} onColor={"var(--primary)"} />
          </div>
          {// TODO consider adding ability to change default naming convention in app settings
          }
        </>,
        'requirements': [form.first, form.username || (form.useDefault && form.defaultUsername)],
        'requirementFieldNames': ['First Name', 'Username']
    },
    {
      "header": 'Contact Info:',
      "body": 
      <>
        <Input name='phone' placeholder='Phone Number' value={form.phone} setValue={handleUpdate} isPhoneNumber={true} maxLength={14} />
      </>,
    },
    {
      "header": 'Role:',
      "body": 
      <>
        <ButtonPrimary 
          text='User' 
          className={form.role != 'user' ? 'manageUsersButtonSecondary' : ''}
          onClick={() => {
            setForm(prev => ({...prev, role: 'user'}))
          }}
        />
        <ButtonPrimary 
          text='Manager' 
          className={form.role != 'manager' ? 'manageUsersButtonSecondary' : ''}
          onClick={() => {
            setForm(prev => ({...prev, role: 'manager'}))
          }}
        />
      </>,
      'requirements': [form.role],
      'requirementFieldNames': ['Role']
    },
    {
      "header": 'Confirm:',
      "body": 
      <>
        <span>{capitalize(form.role)}: {capitalize(form.first)} {capitalize(form.last)}</span>
        {form.phone} 
      </>,
    }
  ]

  const handleSubmit = () => {
    CallAPI('/api/v1/user', 'POST', {
      name: (`${capitalize(form.first).trim()} ${capitalize(form.last).trim()}`),
      username: form.useDefault ? form.defaultUsername : form.username,
      phone: form.phone,
      role: form.role
    })
    .then(result => {
      if (result.error) {
        createNotification({
          type: 'danger',
          message: result.error,
        })
      } else {
        setFormComplete(true)
      }
    })
  }

  return (
    <>
    <Overlay show={formComplete}>
      <div className='overlayTitle'>
        Success
      </div>

      <div className='overlayButtonGroup'>
        <ButtonPrimary text='Make Another' onClick={() => window.location.href = '/management/users'}/>
        <ButtonPrimary text='Done' onClick={() => window.location.href = '/'} />        
      </div>
    </Overlay>

    <img className='manageBackground' src={UsersBackground} alt='Background' />

    <div className='manageContainer'>      
      <ManageCard>
        <ManageCardHeader>
          {pages[currentPage]?.header}
        </ManageCardHeader>

        <ManageCardBody>
          {pages[currentPage]?.body}
        </ManageCardBody>

        <ManageCardFooter 
          pages={pages}
          steps={steps}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          handleSubmit={handleSubmit}
          nextFunction={async () => {
            if (currentPage !== 0) return true

            return CallAPI("/api/v1/user/availability?username="+(form.useDefault ? form.defaultUsername : form.username))
            .then(result => {
              if (result.error || result.available === undefined) {
                createNotification({
                  type: 'warn',
                  message: 'Could not validate username.',
                  timeout: 3500,
                })
                return true
              }
              
              if (result.available === 'true') {
                return true
              } else {
                createNotification({
                  type: 'warn', 
                  message: 'The selected username has been taken.',
                  timeout: 3500, 
                })
                return false
              }
            })
          }}
        />
      </ManageCard>
    </div>
    </>
  )
}

export default Users