/*
    Purpose: Dropdown selector element
    Author: Amos Gordon

    Latest Upadte: 6/24/2023
*/

import React, { useState } from 'react'
import styled from 'styled-components'

import {
    ChevronDown
} from 'react-ionicons'


const StyledDropDownButton = styled.div`
    all: unset;
        
    padding: .5rem;

    border: none;
    border-radius: 5px;

    text-align: center;

    transition: box-shadow 0.3s ease-in-out;
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0);

    background-color: var(--primary, rgb(71, 128, 123));
    color: var(--text-on-primary, rgb(229, 227, 241));

    & > span > svg {
        color: inherit;
        transition: transform .5s;
    }

    & > span.open > svg {
        transform: rotate(-180deg);
    }
`

const StyledContentContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows .5s;

    margin-top: 20px;

    color: var(--text-on-primary, rgb(229, 227, 241));

    &.open {
        grid-template-rows: 1fr;
    }
`

const StyledContent = styled.div`
    overflow: hidden;

    border-radius: 5px;

    background-color: var(--primary, rgb(71, 128, 123));
`

const StyledItem = styled.div`
    cursor: pointer;
    padding: .25rem;
    border-bottom: solid rgba(0, 0, 0, 0.5) 1px;

    &:hover {
        background-color: var(--secondary, rgb(163, 217, 213));
        color: var(--text-on-secondary, black);
    }

    &:first-child {
        border-radius: 5px 5px 0 0;
    }

    &:last-child {
        border-radius: 0 0 5px 5px;
        border: none;
    }
`

const DropDown = ({
    children,
    value,
    setValue,
    placeholder="Value",
    autoClose=true
}) => {
    const [open, setOpen] = useState(false)
    const onClick = (value) => {
        autoClose && setOpen(false)

        setValue(value)
    }

    return (
        <div>
            <StyledDropDownButton onClick={() => {setOpen(!open)}}>
                {value ? value : placeholder} <ChevronDown className={open ? 'open' : ''} style={{verticalAlign: 'bottom'}} />
            </StyledDropDownButton>
            <StyledContentContainer className={open ? ' open' : ''}>
                <StyledContent>
                    {React.Children.map(children, child => {
                        return React.cloneElement(child, {setValue: onClick})
                    })}
                </StyledContent>
            </StyledContentContainer>
        </div>
    )
}

export const DropDownItem = ({children, value, setValue}) => {
    return (
        <StyledItem onClick={() => setValue(value)}>
            {children}
        </StyledItem>
    )
}

export default DropDown