/*
    Purpose: Text are input 
    Author: Amos Gordon

    Latest Upadte: 6/24/2023
*/

import React from 'react'
import styled from 'styled-components'


const StyledContianer = styled.div`
  --bottomPadding: .25rem;
  --topPadding: .2rem;
  --xPadding: .2rem;

  margin-top: calc(1rem + var(--inputYGap, 5px));
  margin-bottom: var(--inputYGap, 5px);

  position: relative;
`

const StyledPlaceholder = styled.div`
  position: absolute;
  top: var(--topPadding);
  left: var(--xPadding);

  pointer-events: none;

  transition: transform .3s ease, top .3s ease;
  backface-visibility: hidden;
  transform-origin: left;

  user-select: none;
  -webkit-user-select: none; 
  -ms-user-select: none;
`

const StyledTextArea = styled.textarea`
  all: unset;

  padding: var(--topPadding) var(--xPadding) var(--bottomPadding) var(--xPadding);

  text-align: start;

  box-sizing: border-box;

  border: 2px solid black;
  border-radius: 5px;

  max-width: 100%;
  width: 100%;

  &:focus, &:not(:empty) {
    border-color: var(--primary, rgb(71, 128, 123));
  }

  &:focus + ${StyledPlaceholder}, &:not(:empty) + ${StyledPlaceholder} {
    top: -1.25rem;
    transform: translateY(calc(-1*(var(--topPadding) - 25%))) scale(.8);
    color: var(--primary, rgb(71, 128, 123));
  }
`


const TextArea = ({
    value,
    name,
    setValue,
    type="text",
    placeholder="Type here...",
    className='',
    maxLength,
    style,
    onEnter=()=>{}
}) => {
  return (
    <StyledContianer className={(className.length > 0 ? ' ' : '') + className}>
      <StyledTextArea  
        type={type} 
        value={value} 
        name={name}
        onChange={e => {setValue(e)}}
        maxLength={maxLength} 
        style={style}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            onEnter()
          }
        }
      } />
      <StyledPlaceholder>
          {placeholder}
      </StyledPlaceholder>
    </StyledContianer>
  )
}

export default TextArea