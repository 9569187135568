/*
    Purpose: Standardizes API calls and returned error codes
    Author: Amos Gordon

    Latest Upadte: 6/24/2023
*/

/**
 * 
 * @returns false OR array
 */
const TokenData = () => {

    const base64Data = localStorage.getItem('JWT')?.split('.')[1]

    if (base64Data === undefined) {
        return false
    } else {
        return JSON.parse(atob(base64Data))
    }
}

export default TokenData