import React from 'react'

import { HashLoader } from 'react-spinners'

const LoadingWrapper = ({ children, loading, className }) => {
    return (
        <>
        {loading ? (
            <div className={'centerChildX' + (className ? ' '+className : '')} >
                <HashLoader />
            </div>
        ) : (
            {...children}
        )}
        </>

    )
}

export default LoadingWrapper