import React, { useEffect, useRef, useState } from 'react'

import ButtonPrimary from '../../../components/global/buttons/ButtonPrimary'

import CallAPI from '../../../components/functional/CallAPI'
import TokenData from '../../../components/functional/TokenData'

import { CloseOutline } from 'react-ionicons'
import FileInput from '../../../components/global/inputs/elementInset/FileInput'
import { BeatLoader } from 'react-spinners'
import { useNotification } from '../../../components/global/Notifications'

const {u_id, name} = TokenData()

const getLocalDate = (date) => {
    return (new Date(date.replaceAll('-', '/').replace('T', ' ')+' UTC'))
    .toLocaleDateString([], {hour: 'numeric', minute: 'numeric', day: '2-digit', month: 'short'})
}

const upload = (file, id, createNotification) => {
    let formData = new FormData()

    formData.append('file', file)
    formData.append('job_id', id)

    return CallAPI(
        '/api/v1/job/picture', 
        "POST", 
        formData, 
        {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
        }
    )
    .then(result => {
        if (result.error) {
            createNotification({
                type: 'danger', 
                message: <span><b>Could not upload {formData.get('file').name}:</b>{result.error}</span>,
                timeout: 3500,
            })
            return false
        }

        return result.id
    })

}


const PictureElement = ({ picture }) => {
    const createNotification = useNotification()
    const pictureRef = useRef()

    const deleteFile = async (id) => {
        return CallAPI('/api/v1/job/picture', 'DELETE', {
            id: id,
        })
        .then(result => {
            if (result.error) {
                createNotification({
                    type: 'danger',
                    message: <span><b>Picture Delete Error:</b>{result.error}</span>,
                    timeout: 3500,
                })
                return false
            }

            createNotification({
                type: 'success',
                message: 'Picture deleted',
                timeout: 2500,
            })

            return true
        })
    }

    return (
        <div className='jobEntityPictureWrapper' ref={pictureRef}>
            <img 
                className='jobEntityPicture'
                src={picture[1].clientSide ? picture[0] : `data:image/png;base64,${picture[0]}`} 
                alt={picture[1].id}  
            />
            {picture[1].u_id == u_id && (
                <CloseOutline 
                    className="jobEntityPictureDelete" 
                    onClick={() => {
                        deleteFile(picture[1].id)
                        .then(success => {
                            if (success) {
                                pictureRef.current.remove()
                            }
                        })
                    }}
                />
            )}
            <div className='jobEntityPictureMetadata'>
                <div>{picture[1].user}</div>
                <div>
                    {getLocalDate(picture[1].date).replace(',', ', \n')}
                </div>
            </div>
        </div>
    )
}


const Pictures = ({ id }) => {
    const createNotification = useNotification()
    const [form, setForm] = useState({
        currentFile: undefined,
        previewImage: undefined,
        message: '',
    })

    const [uploading, setUploading] = useState(false)
    const [pictureArray, setPictureArray] = useState([])

    const selectFile = (e) => {
        setForm(prev => ({
            ...prev, 
            currentFile: e.target.files[0],
            previewImage: URL.createObjectURL(e.target.files[0]),
            message: '',
        }))
    }

    const submit = () => {
        if (!form.currentFile) return (
            createNotification({
                type: 'warn', 
                message: <span><b>Pictures Warning: </b>No picture has been selected</span>,
                timeout: 3500, 
            })
        )
        setUploading(true)

        upload(form.currentFile, id, createNotification)
        .then((file_id) => {
            if (!file_id) {
                setForm(prev => ({...prev, message: 'failed'}))
            } else {
                setPictureArray(prev => ([
                    [
                        form.previewImage, 
                        {
                            id: file_id,
                            user: name,
                            date: (new Date().toISOString()),
                            u_id: u_id,
                            clientSide: true,
                        }
                    ],
                    ...prev
                ]))
                
                setForm({
                    currentFile: undefined,
                    previewImage: undefined,
                    message: '',
                })
            }

            setUploading(false)
        })
    }

    useEffect(() => {
        CallAPI('/api/v1/job/picture?job_id='+id)
        .then(result => {
            if (result.error) return (
                createNotification({
                    type: 'danger',
                    message: <span><b>Pictures Error:</b>{result.error}</span>,
                })
            )

            if (typeof result == 'object') {
                setPictureArray(result)
            }      
        })
    }, [])

    return (
        <>  
            <h2 className='align-self-start'>Pictures:</h2>
            <FileInput 
                setValue={(e) => {selectFile(e)}} 
                value={form.currentFile} 
                previewImage={form.previewImage} 
                error={form.message} 
            />

            <ButtonPrimary text='Upload' className='align-self-start' onClick={() => submit()}/>

            <div className='jobEntityPicturesContainer'>
                {uploading && <BeatLoader style={{marginRight: 'auto', marginLeft: 'auto'}}/>}
                {pictureArray?.map((picture, index) => (
                    <PictureElement picture={picture} key={index} />
                ))}
            </div>
        </>
    )
}

export default Pictures