import React, { useState, useEffect } from 'react'
import { ChevronBack, ChevronForward } from 'react-ionicons';
import { HashLoader } from 'react-spinners';

import CallAPI from '../functional/CallAPI';
import { useNotification } from '../global/Notifications';

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];


const Calendar = () => {
  const [date, setDate] = useState(new Date());
  const [jobsList, setJobsList] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const createNotification = useNotification()

  const localeDate = date.toLocaleDateString()

  useEffect(() => {
    setLoading(true)
    setError(null)

    CallAPI('/api/v1/job?&date='+localeDate)
    .then(result => {

        setLoading(false)
        if (result.error !== undefined) {
          createNotification({
            type: 'danger',
            message: <span><b>Calendar: </b>{result.error}</span>,
          })
          setError('Could not load.')
          return 
        }

        setJobsList(result)
        return 
    })
  }, [date]);

  
  // get the current month, day, and year from the provided date
  const year = date.getFullYear();
  const month = date.getMonth();

  // create a new date object set to the first day of the current month
  const firstDay = new Date(year, month, 1);

  // get the number of days in the current and previous month
  const numDays = new Date(year, month + 1, 0).getDate();
  const prevNumDays = new Date(year, month, 0).getDate();

  // get the weekday index of the first day of the month (0 = Sunday, 1 = Monday, etc.)
  const firstWeekday = firstDay.getDay();

  // create an array of day cells, where each cell is either a number or an empty space
  const cells = [];

  let prevMonth = month
  let usePrevYear = false

  if (month === 0) {
    prevMonth = 12
    usePrevYear = true
  }

  for (let i = 0; i < firstWeekday; i++) {
    cells.push({
      class: " outsideMonth", 
      value: prevNumDays-i,
      date: `${prevMonth}/${(prevNumDays-i)}/${(usePrevYear ? year-1 : year)}`
    });
  }
   
  cells.reverse() 

  for (let i = 1; i <= numDays; i++) {
    cells.push({
      class: "",
      value: i,
      date: `${(month+1)}/${i}/${(year)}`
    });
  }

  // split the cells array into rows of 7 
  const rows = [];
  for (let i = 0; i < cells.length; i += 7) {
    rows.push(cells.slice(i, i + 7));
  }

  let nextMonth = month+2
  let useNextYear = false

  if (nextMonth === 13) {
    nextMonth = 1
    useNextYear = true
  }

  // pad final row to 7 with days outside current month
  for (let i = 1; rows[rows.length-1].length < 7; i++) {
    rows[rows.length-1].push({
      class: " outsideMonth", 
      value: i,
      date: `${nextMonth}/${i}/${(useNextYear ? year+1 : year)}`
    })
  }

  return (
      <div className='calendarContainer'>
        <table className='calendarTable'>
          <thead>
            <tr>
              <th 
                colSpan="7"
              >
                <div className='calendarTableHeader'>
                  <h2>
                    {`${firstDay.toLocaleDateString("en-US", {month: "long",})} ${year}`}
                  </h2>
                  
                  <div className='d-flex gap-2'>
                    <ChevronBack className="hover-pointer" onClick={() => {
                      setDate(new Date(date.getFullYear(), date.getMonth()-1, 1))
                    }}/>
                    <ChevronForward className="hover-pointer" onClick={() => {
                      setDate(new Date(date.getFullYear(), date.getMonth()+1, 1))
                    }}/>
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              {days.map((day, dayIndex) => (
                <th className='calendarDOW' key={dayIndex}>
                  {day}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cell, cellIndex) => (
                  <td 
                    key={cellIndex} 
                    className='hover-pointer'
                    onClick={() => {cell.date !== localeDate && setDate(new Date(cell.date))}}
                  >
                    <div 
                      className={'calendarCell' + 
                        (cell.date === localeDate ? ' active' : "") + 
                        (cell.date === (new Date().toLocaleDateString()) ? ' today' : '') + 
                        cell.class
                      }
                    >
                      <span>{cell.value}</span>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className='calendarJobsContainer'> 
          {!loading ? (
            jobsList.length ? (
              jobsList?.map((job, index) => (
                <div 
                  className='calendarJobsElement'
                  key={index}
                  onClick={() => {
                    window.location.href = '/entity_info?entity=job&id='+job.jobID
                  }}
                >
                  <div className='calendarJobsElementHeader'>
                    <h2>{job.customer}</h2> 
                    <span className='center-child-y'>{job.type}</span>
                  </div>
                  <div className='calendarJobsElementDescription'>{job.description}</div>
                </div>
              ))
            ) : (
              !error ? (
                <h1 className='centerChildX color-primary'>No Jobs</h1>
              ) : (
                <div className='centerChildX'>
                  <h3 className='color-danger'>
                    {error}
                  </h3>
                </div>
              )
            )
          ) : (
            <div className='spinner'>
              <HashLoader />
            </div>
          )}
        </div>
      </div>
  );
}


export default Calendar