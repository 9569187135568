import React, {useEffect, useState} from 'react'

import ButtonPrimary from '../components/global/buttons/ButtonPrimary'
import Carousel from '../components/global/Carousel'
import Input from '../components/global/inputs/elementInset/Input'
import { ArrowBack, Call } from 'react-ionicons'
import Overlay from '../components/global/Overlay'
import DateInput from '../components/global/inputs/placeholderAbove/DateInput'
import TextArea from '../components/global/inputs/elementInset/TextArea'
import { useNotification } from '../components/global/Notifications'
import CallAPI from '../components/functional/CallAPI'
import LoadingWrapper from '../components/global/LoadingWrapper'


const Scraper = () => {
  const createNotification = useNotification()

  const [form, setForm] = useState({
    username: '', 
    password: '',
    authPassed: false,
    loading: false,
  })

  const [editState, setEditState] = useState({
    editing: false,
    jobData: {},
    index: 0,
  })

  const [expandCarousel, setExpandCarousel] = useState(false)
  const [carouselIndex, setCarouselIndex] = useState(0)
  const [jobData, setJobData] = useState([])


  const handleLogin = () => {
    if (form.username.length < 1 || form.password < 1) return (
      createNotification({
        type: 'warn', 
        message: 'Please enter a username and password.', 
        timeout: 3500, 
      })
    )

    setForm(prev => ({...prev, loading: true}))

    CallAPI('/api/v1/job/import', 'POST', {
      username: form.username,
      password: form.password,
    })
    .then(result => {
      setForm(prev => ({...prev, loading: false}))
      if (result.error) return (
        createNotification({
          type: 'danger',
          message: result.error,
          timeout: 4000,
        })
      )

      setJobData(result)
      setForm(prev => ({...prev, authPassed: true}))
    })
  }

  const updateJobData = (indexArray, changeArray) => {
    const updatedArray = [...jobData]

    indexArray.map((index) => (
      updatedArray[index] = {
        ...updatedArray[index],
        ...changeArray
      }
    ))

    setJobData(updatedArray)
  }

  const updateEditArray = (field, value) => {
    setEditState({
      ...editState, 
      jobData: {
        ...editState.jobData, 
        [field]: value
      }})
  }

  const formatDate = (date) => {
    if (!date || typeof date !== 'string') return 

    if (date.match('([0-9]{1,2}\/){2}[0-9]{4}')) {
      const splitDate = date.split('/')
      return (`${splitDate[2]}-${splitDate[0].padStart(2, '0')}-${splitDate[1].padStart(2, '0')}`)

    } else if (date.match('[0-9]{4}(-[0-9]{2}){2}')) {
      const splitDate = date.split('-')
      return (`${parseInt(splitDate[1], 10)}/${parseInt(splitDate[2], 10)}/${splitDate[0]}`)
    }
  }

  const handleSubmit = (bypassCheck=false) => {
    const notReviewed = jobData.filter(job => !('formAction' in job))

    if (notReviewed.length > 0 && !bypassCheck) {
      return createNotification({
        type: 'warn', 
        message: <span>
          <b>You have unreviewed jobs:</b>
          These jobs will be treated as excluded.
          <ButtonPrimary 
            text='Submit Anyway' 
            className='m-1'
            onClick={() => handleSubmit(bypassCheck=true)}
          />
        </span>
      })
    }
    
    const confirmedJobs = jobData.filter(job => job.formAction === 'confirm')
    confirmedJobs.map((job) => (
      CallAPI('/api/v1/job', 'POST', {
        date: formatDate(job.date),
        customer: job.customer,
        address: job.address,
        description: job.comment,
        type: job.type,
        uid: job.uid,
        state: "inactive",
      })
      .then(response => {
        if (response.error) return (
          createNotification({
            type: 'danger', 
            message: response.error, 
          })
        )

        setJobData(prev => ([...prev.filter(item => item.uid !== job.uid)]))
        setCarouselIndex(0)
      })
    ))
  }

  return (
    <div className='scraperContainer fullScrollableContainer'>
      <Overlay show={editState.editing}>
        <div>
          <div className='scraperJobHeader'>
            <span>{editState.jobData.uid}</span>
            <DateInput 
              value={formatDate(editState.jobData.date)} 
              setValue={(e) => updateEditArray('date', formatDate(e.target.value))}
            />
          </div>
          <div className='scraperJobTitle'>
            <Input 
              placeholder='Customer' 
              value={editState.jobData.customer} 
              setValue={(e) => updateEditArray('customer', e.target.value)}
              maxLength={35}
            />
          </div>      
          <div className='scraperJobBody'>
            <Input 
              placeholder='Type' 
              value={editState.jobData.type} 
              setValue={(e) => updateEditArray('type', e.target.value)} 
              maxLength={20}
            />
            <Input 
              placeholder='Address' 
              value={editState.jobData.address} 
              setValue={(e) => updateEditArray('address', e.target.value)} 
              maxLength={125}
            />
            <TextArea 
              placeholder='Description' 
              value={editState.jobData.comment} 
              setValue={(e) => updateEditArray('comment', e.target.value)} 
              maxLength={200}
            />
          </div>
        </div>
        <div className='d-flex space-between mt-2'>
          <ButtonPrimary 
            text='Cancel' 
            className='bg-grey' 
            onClick={() => setEditState({...editState, editing: false})} 
          />
          <ButtonPrimary 
            text='Done' 
            onClick={() => {
              const newArray = [...jobData]
              newArray[editState.index] = editState.jobData

              setJobData(newArray)
              setEditState({...editState, editing: false})
            }}
          />
        </div>
      </Overlay>

      <div className='scraperTitleBar'>
        <ArrowBack onClick={() => {window.location.href = '/'}}/>

        <div>
          {form.authPassed && (<>
          <ButtonPrimary 
            text={expandCarousel ? 'Collapse' : 'Expand'} 
            onClick={() => {setExpandCarousel(!expandCarousel)}} 
          />
          <ButtonPrimary 
            text='Confirm All' 
            onClick={() => {updateJobData(jobData.map((_, index) => index), {formAction: 'confirm'})}}
          />
          <ButtonPrimary text='Submit' onClick={() => handleSubmit()}/>
          </>)}
        </div>
      </div>

      <div className='scraperContainer fullScrollableContainer'>
        <LoadingWrapper loading={form.loading} className='mt-2'>
          {!form.authPassed ? (
            <div className='scraperLoginContainer'>
              <h1>Portal Login</h1>

              <Input 
                placeholder='Username' 
                value={form.username} 
                setValue={(e) => {setForm(prev => ({...prev, username: e.target.value}))}}
                onEnter={() => handleLogin()}
                maxLength={100}
              />

              <Input 
                placeholder='Password' 
                type='password' 
                value={form.password} 
                setValue={(e) => {setForm(prev => ({...prev, password: e.target.value}))}}
                onEnter={() => handleLogin()}
                maxLength={100}
              />

              <ButtonPrimary text='Submit' onClick={() => handleLogin()} />
            </div>
          ) : (
            jobData.length > 0 ? (
              <>
              <Carousel expand={expandCarousel} setIndex={(index) => {setCarouselIndex(index)}} >
                {jobData?.map((job, index) => (
                  <React.Fragment key={index}>        
                    <div className={'scraperJob' + (job.formAction === 'confirm' ? ' scraperConfirmed' : '') + (job.formAction === 'exclude' ? ' scraperExcluded' : '')}>
                      <div className='scraperJobHeader'>
                        <span>{job.uid}</span>
                        <span>{job.date}</span>
                      </div>

                      <div className='scraperJobTitle'>
                          {job.customer}
                      </div>

                      <div className='scraperJobBody'>
                        <div><b>Type:</b> {job.type}</div>
                        <div><b>Address:</b> {job.address}</div>
                        <div><b>Description:</b> {job.comment}</div>
                      </div>
                    </div>

                    {expandCarousel && (
                      <div className='scraperControlButtons'>
                        <ButtonPrimary 
                          text='Exclude' 
                          className='bg-danger' 
                          onClick={() => {updateJobData([index], {formAction: 'exclude'})}}
                        />

                        <ButtonPrimary 
                          text='Edit' 
                          onClick={() => setEditState({editing: true, jobData: jobData[index], index: index})}
                        />

                        <ButtonPrimary 
                          text='Confirm' 
                          onClick={() => {updateJobData([index], {formAction: 'confirm'})}} 
                        />
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </Carousel>

              {!expandCarousel && (
                <div className='scraperControlButtons outter'>
                  <ButtonPrimary 
                    text='Exclude' 
                    className='bg-danger' 
                    onClick={() => {updateJobData([carouselIndex], {formAction: 'exclude'})}}
                  />
                  <ButtonPrimary 
                    text='Edit' 
                    onClick={() => setEditState({editing: true, jobData: jobData[carouselIndex], index: carouselIndex})}
                  />
                  <ButtonPrimary 
                    text='Confirm' 
                    onClick={() => {updateJobData([carouselIndex], {formAction: 'confirm'})}}
                  />
                </div>
              )}
              </>
            ) : (
              <h2 className='text-align-center'>
                No Jobs Found.
              </h2>
            )
          )}
        </LoadingWrapper>
      </div>
      
    </div>
  )
}

export default Scraper