import React, { useState, useEffect } from "react"

import CallAPI from "./functional/CallAPI"
import LoadingWrapper from "./global/LoadingWrapper"
import Input from "./global/inputs/elementInset/Input"

const EntitySelector = ({
    type,
    entityList=[],
    setEntityList=()=>{} 
}) => {
    const [loading, setLoading] = useState(false)
    const [entitySourceList, setEntitySourceList] = useState([])
    const [filter, setFilter] = useState('')

    const addEntity = (entity) => {
        setEntityList(prev => ([...prev, entity]))
    }

    const removeEntity = (id) => {
        setEntityList(entityList.filter(entity => entity.id != id))
    }
    
    useEffect(() => {
        setLoading(true)
        CallAPI(type === 'users' ? '/api/v1/user' : (type === 'vehicles' && '/api/v1/vehicle'))
        .then(data => {
            if (data.error !== undefined) return // TODO NOW handle error
            
            if (type === 'users') {
                setEntitySourceList(data.map(({ name, u_id }) => ({ name, id: u_id })))

            } else if (type === 'vehicles') {
                setEntitySourceList(data.map(({ name, vic_id }) => ({ name, id: vic_id })))
            }
        })
        .then(() => {
            setLoading(false)
        })
    }, [])
    
    return (
        <div className="entitySelectorContainer">
            <Input placeholder="Filter" value={filter} setValue={(e) => setFilter(e.target.value)} />
            <LoadingWrapper loading={loading}>
                <>
                <div className="entitySelectorMain">
                    <div className="entitySelectorGroup">
                        {entityList
                        .map((entity, index) => (
                            <div className="entitySelectorItem active hover-pointer" key={index} onClick={() => removeEntity(entity.id)}>
                                {entity.name}
                            </div>
                        ))}
                    </div>
                    <div className="entitySelectorGroup">
                        {entitySourceList
                        .filter(entitySource => 
                            !entityList.some(entity => entitySource.id === entity.id)
                        )
                        .filter(entity => entity.name.toLowerCase().includes(filter.toLowerCase()))
                        .map((entity, index) => (
                            <div className="entitySelectorItem hover-pointer" key={index} onClick={() => addEntity(entity)}>
                                {entity.name}
                            </div>
                        ))}
                    </div>
                </div>
                </>
            </LoadingWrapper>
        </div>
    )
}

export default EntitySelector