import React, { useEffect, useState } from 'react'

import { 
    ChevronForward,
    ChevronBack
} from 'react-ionicons'
import {HashLoader} from 'react-spinners'

const WeatherWidget = ({ data, loadingError }) => {
    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);

    useEffect(() => {
        setLoading(true)

        if (data || loadingError) {
            setLoading(false)
        }

    }, [data, loadingError])

    return (
        <div className='widget'>
            {loading ? (
                <div className='spinner'>
                    <HashLoader />
                </div>
             ) : (
                !loadingError ? (
                    
                    <div className='weatherWidget'>
                        <div className='weatherWidgetHeader'>  
                            <div className='weatherWidgetButton' onClick={() => {setPage(1)}}>
                                <div className={page === 1 ? 'd-none' : 'hover-pointer'} >
                                    <ChevronBack />
                                </div>
                            </div>  

                            <div className='weatherWidgetTitle'>
                                {data.location}
                            </div> 

                            <div className='weatherWidgetButton' onClick={() => {setPage(2)}}>
                                <div className={page === 2 ? 'd-none' : 'hover-pointer'}>
                                    <ChevronForward />
                                </div>
                            </div>
                        </div>    
                        <div className='weatherWidgetBody'>
                            <div className='weatherWidgetCol'>
                                <img src={data.icon} alt='Weather Icon' />
                            </div>
                            {page === 1 ? (
                                <div className='weatherWidgetCol'>
                                    <div>
                                        <h3>{data.text}</h3>
                                        <span>{data.currentTemp}&#8457;</span>
                                    </div>
                                </div>
                            ) : (
                                <div className='weatherWidgetCol'>
                                    <div>
                                        <h3>High:</h3>
                                        {data.forecastHigh}&#8457;
                                    </div>
                                    <div>
                                        <h3>Low:</h3> 
                                        {data.forecastLow}&#8457;
                                    </div>
                                </div>
                            )}
                        </div>                  
                    </div>
                ) : (
                    <div className='centerChildX'>
                        <h3 className='color-danger'>
                            Could not load.
                        </h3>  
                    </div>
                    )
            )}
        </div>
    )
}

export default WeatherWidget