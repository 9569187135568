import React from 'react'

import {HashLoader} from 'react-spinners'

const SplashScreen = () => {
  return (
    <div className='splashScreenLoader'>
      <HashLoader size={'100%'}/>
    </div>
  )
}

export default SplashScreen