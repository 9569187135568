import React, {useEffect, useState} from 'react'

import { 
    ArrowBack, ChevronDown,
} from 'react-ionicons'
import SearchBar from '../components/global/inputs/SearchBar'
import CallAPI from '../components/functional/CallAPI'
import { useNotification } from '../components/global/Notifications'
import LoadingWrapper from '../components/global/LoadingWrapper'
import DefaultProfPic from '../images/default_teal.png'
import DefaultVehicle from '../images/default_truck.png'
import DefaultJob from '../images/default_job.png'
import DateInput from '../components/global/inputs/placeholderAbove/DateInput'
import ButtonPrimary from '../components/global/buttons/ButtonPrimary'

const iconHeight = '1.75rem'

const jobStateInfo = {
    'inactive': {
        colorIndicator: 'var(--danger)',
        term: 'Inactive'
    },
    'active': {
        colorIndicator: 'var(--neutral)',
        term: 'In Progress',
    },
    'pending_review': {
        colorIndicator: 'var(--warn)',
        term: 'Pending Review',
    },
    'complete': {
        colorIndicator: 'var(--success)',
        term: 'Complete',
    }
}

const Archive = () => {
    const createNotification = useNotification()

    const urlParams = new URLSearchParams(window.location.search)
    const entityFilterParam = urlParams.get('entity_filter')
    const startDateParam = urlParams.get('start_date')
    const endDateParam = urlParams.get('end_date')
    const searchParam = urlParams.get('search')
    const typeParam = urlParams.get('type')
    
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [filters, setFilters] = useState({
        users: entityFilterParam ? entityFilterParam.includes('user') : true,
        jobs: entityFilterParam ? entityFilterParam.includes('job') : true,
        vehicles: entityFilterParam ? entityFilterParam.includes('vehicle') : true,
        assigned: entityFilterParam ? entityFilterParam.includes('assigned') : true,
        startDate: startDateParam ? startDateParam : '',
        endDate: endDateParam ? endDateParam : '',
        type: typeParam ? typeParam : ''
    }) // TODO NOW apply filters to existing pulls
    const [search, setSearch] = useState(searchParam ? searchParam : '')
    const [searchResults, setSearchResults] = useState({})    

    const entityFilter = `${!filters.users ? 'user,' : ''}${!filters.jobs ? 'job,' : ''}${!filters.vehicles ? 'vehicle,' : ''}${!filters.assigned ? 'assigned,' : ''}`.replace(/,+$/, '')

    const handleSubmit = (bypass=false) => {
        if (search.length < 1 && !bypass) return (createNotification({
            type: 'warn', 
            message: 'Please enter a search term.',
            timeout: 3500,

        }))

        if (!bypass) {
            const entityFilterFlip = `${filters.users ? 'user,' : ''}${filters.jobs ? 'job,' : ''}${filters.vehicles ? 'vehicle,' : ''}${filters.assigned ? 'assigned,' : ''}`.replace(/,+$/, '')
            const getParams = [
                (search ? 'search='+search : ''),
                (entityFilterFlip ? 'entity_filter='+entityFilterFlip : ''),
                (filters.startDate ? 'start_date='+filters.startDate : ''),
                (filters.endDate ? 'end_date='+filters.endDate : '')
            ].filter(item => !!item).join('&')

            getParams.length > 0 && window.history.pushState({}, '', '/archive?'+getParams)
        }

        setLoading(true)
        CallAPI('/api/v1/app/search?'+'search='+search+
            (entityFilter.length > 1 ? '&entity_filter='+entityFilter : '')+
            (filters.startDate ? '&start_date='+filters.startDate : '')+
            (filters.endDate ? '&end_date='+filters.endDate : '')+
            (filters.type ? '&type='+filters.type : '')
        )
        .then(result => {
            if (result.error) return (createNotification({
                type: 'danger',
                message: result.error,
            }))

            setSearchResults(result)
            setLoading(false)
        })
    }

    const capitalize = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    const formatDate = (date) => {
        const dateSplit = date?.split('-')
        if (!date || dateSplit.length < 3) return
        return `${parseInt(dateSplit[1])}/${parseInt(dateSplit[2])}/${dateSplit[0]}`
    }

    useEffect(() => {
        if (entityFilterParam || searchParam) {
            handleSubmit(true)
        }
    }, [])


    return (
        <div className='archiveContainer'>
            <div className='archiveSidebar'>
                <ArrowBack className='w-fit' height={iconHeight} width={iconHeight} onClick={() => {window.location.href = "/"}}/>
                <div className='archiveFiltersContainer'>
                    {(filters.startDate || filters.endDate) && 
                        <div>
                            <h4>Date Range: </h4>
                            {filters.startDate ? formatDate(filters.startDate) : 'Not Set'} - {filters.endDate ? formatDate(filters.endDate) : 'Not Set'}
                        </div>
                    }
                    {entityFilter.length > 0 &&
                        <div>
                            <h4>Entity Exclusions: </h4>
                            {entityFilter.split(',')?.map(exclusion => capitalize(exclusion)).join(', ')}
                        </div>
                    }
                </div>
            </div>

            <div className={'archiveFiltersDropDownContainer' + (open ? ' open' : '')}>
                <div className='archiveFiltersDropDown'>
                    <div className='archiveFiltersGroup'>
                        <div className='title'>Entity Type</div>
                        <div>
                            <input 
                                type='checkbox' 
                                checked={filters.users} 
                                onChange={() => setFilters(prev => ({...prev, users: !filters.users}))} 
                            />
                            User
                        </div>                    
                        <div>
                            <input 
                                type='checkbox' 
                                checked={filters.jobs}
                                onChange={() => setFilters(prev => ({...prev, jobs: !filters.jobs}))}
                            />
                            Job
                        </div>
                        <div>
                            <input 
                                type='checkbox' 
                                checked={filters.vehicles}
                                onChange={() => setFilters(prev => ({...prev, vehicles: !filters.vehicles}))}
                            />
                            Vehicle
                        </div>
                        <div>
                            <input 
                                type='checkbox' 
                                checked={filters.assigned}
                                onChange={() => setFilters(prev => ({...prev, assigned: !filters.assigned}))}
                            />
                            Assigned Job
                        </div>
                    </div>

                    <div className='archiveFiltersGroup'>
                        <div className='title'>
                            Date Range 
                            <ButtonPrimary 
                                className='archiveDateClear' 
                                text='Clear'
                                onClick={() => setFilters(prev => ({...prev, startDate: '', endDate: ''}))}
                            />
                        </div>
                        <div className='archiveDateFilters'>
                            <DateInput value={filters.startDate} setValue={(e) => {
                                if (!filters.endDate || !((new Date(e.target.value).getTime()) > (new Date(filters.endDate).getTime()))) {
                                    setFilters(prev => ({...prev, startDate: e.target.value}))
                                } else {
                                    createNotification({
                                        type: 'warn',
                                        message: 'Start date must be before end date.',
                                        timeout: 2500,
                                    })
                                }
                            }} /> 
                            <span>-</span>
                            <DateInput value={filters.endDate} setValue={(e) => {
                                if (!filters.startDate || !((new Date(e.target.value).getTime()) < (new Date(filters.startDate).getTime()))) {
                                    setFilters(prev => ({...prev, endDate: e.target.value}))
                                } else {
                                    createNotification({
                                        type: 'warn',
                                        message: 'End date must be before start date.',
                                        timeout: 2500,
                                    })
                                }
                            }} />
                        </div>  
                    </div>
                </div>                
            </div>

            <div 
                className='archiveFiltersChevron hover-pointer' 
                onClick={() => setOpen(!open)}
            >
                <ChevronDown style={{transform: (open ? 'rotate(180deg)' : ''), transition: 'transform 800ms'}}/>
            </div>

            <div className='archiveMain'>
                <div className='archiveSearch'>
                    <SearchBar 
                        value={search} 
                        setValue={setSearch} 
                        onSubmit={() => handleSubmit()}
                    />
                </div> 
                <div className='archiveResults'>
                    <LoadingWrapper loading={loading}>
                        <>
                        {searchResults.jobs?.map((job, index) => (
                            <div className='archiveCard hover-pointer' key={index} onClick={() => {window.location.href = '/entity_info?entity=job&id='+job.job_id}}>
                                <img 
                                    className='archiveProfilePicture'
                                    src={DefaultJob}
                                    alt='Job Image'
                                    
                                />
                                <div className='archiveCardBody'>
                                    <div className='archiveCardContext'>{job.date && formatDate(job.date)}</div>
                                    <div className='archiveCardTitle'>{job.customer}</div>
                                    <div className='archiveCardPills'>
                                        <div className='archiveCardPill' style={{'--borderColor': `${jobStateInfo[job.state].colorIndicator}`}}>{jobStateInfo[job.state].term}</div>
                                        {job.type && <div className='archiveCardPill'>{job.type}</div>}
                                    </div>
                                </div>
                            </div>
                        ))}

                        {searchResults.users?.map((user, index) => (
                            <div className='archiveCard hover-pointer' key={index} onClick={() => {window.location.href = '/entity_info?entity=user&id='+user.u_id}}>
                                <img 
                                    className='archiveProfilePicture'
                                    src={user.profile_picture ? `data:image/png;base64,${user.profile_picture}` : DefaultProfPic}
                                    alt='Profile Picture'
                                />
                                <div className='archiveCardBody'>
                                    <div className='archiveCardContext'>{user.username}</div>
                                    <div className='archiveCardTitle'>{user.name}</div>
                                    <div className='archiveCardPills'>
                                        <div className='archiveCardPill'>{capitalize(user.role)}</div>
                                    </div>
                                </div>
                            </div>
                        ))}

                        {searchResults.vehicles?.map((vehicle, index) => (
                            <div className='archiveCard hover-pointer' key={index} onClick={() => {window.location.href = '/entity_info?entity=vehicle&id='+vehicle.vic_id}}>
                                <img 
                                    className='archiveProfilePicture'
                                    src={DefaultVehicle}
                                    alt='Profile Picture'
                                />
                                <div className='archiveCardBody'>
                                    <div className='archiveCardTitle'>{vehicle.vic_name}</div>
                                </div>
                            </div>
                        ))}

                        {searchResults.assigned?.map((job, index) => (
                            <div className='archiveCard hover-pointer' key={index} onClick={() => {window.location.href = '/entity_info?entity=job&id='+job.job_id}}>
                                <img 
                                    className='archiveProfilePicture'
                                    src={DefaultJob}
                                    alt='Job Image'
                                    
                                />
                                <div className='archiveCardBody'>
                                    <div className='archiveCardContext'>{job.date && formatDate(job.date)}</div>
                                    <div className='archiveCardTitle'>{job.customer}</div>
                                    <div className='archiveCardPills'>
                                        <div className='archiveCardPill' style={{'--borderColor': `${jobStateInfo[job.state].colorIndicator}`}}>{jobStateInfo[job.state].term}</div>
                                        {job.type && <div className='archiveCardPill'>{job.type}</div>}
                                    </div>
                                </div>
                            </div>
                        ))}
                        </>
                    </LoadingWrapper>
                </div>
            </div>
        </div>
    )
}

export default Archive