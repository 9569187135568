/*
    Purpose: Provides dynamic right-click functionalty to direct child
    Author: Amos Gordon

    Latest Upadte: 6/24/2023
*/

import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom/client'
import styled from 'styled-components'

let menuContainer = null
let cleanUp = null

const StyledContextMenu = styled.div`
    position: fixed;
    z-index: 100;
    min-width: 4rem;
    min-height: 2rem;
    border-radius: 5px;
    box-shadow: -3px 3px 8px -2px grey;

    padding: 5px;

    background-color: white;
`

const StyledTitle = styled.div`
    padding: 0 10px 7px 10px;
`

const StyledButton = styled.div`
    padding: 7px 10px 7px 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: lightgrey;
    }
`

const Menu = ({ position, options }) => {
    const menuRef = useRef(null)

    const removeMenu = () => {
        menuContainer.remove()
        document.removeEventListener('click', handleClick)
        document.removeEventListener('contextmenu', handleRightClick)
    }

    const handleClick = (e) => {
        if (!menuRef.current.contains(e.target))
        removeMenu()
    }

    const handleRightClick = (e) => {
        if (!e.target.getAttribute('data-has-context-menu')) {
            removeMenu()
        }
    }

    cleanUp = () => {
        document.removeEventListener('click', handleClick)
        document.removeEventListener('contextmenu', handleRightClick)
    }

    useEffect(() => {
        document.addEventListener('click', handleClick)
        document.addEventListener('contextmenu', handleRightClick)

        return () => {
            document.removeEventListener('click', handleClick)
            document.removeEventListener('contextmenu', handleRightClick)
        }
    }, [])

    return (
        <StyledContextMenu 
            ref={menuRef}
            style={{
                top: position.y,
                left: position.x,
            }}
        >
            {options.title && <StyledTitle>
                {options.title}
            </StyledTitle>}
            {options.buttons?.map((option, index) => (
                <StyledButton 
                    key={index} 
                    onClick={() => {
                        option.onClick()
                        removeMenu()
                    }}
                >
                    {option.text}
                </StyledButton>
            ))}
        </StyledContextMenu>
    )
}

const ContextMenu = (e, options) => {
    const {clientX, clientY} = e

    if (menuContainer) {
        menuContainer.remove()
        cleanUp && cleanUp()
    } 

    const element = <Menu position={{x: clientX, y: clientY}} options={options} />

    // translate JSX to HTML and render to target element
    const container = document.createElement('div')
    ReactDOM.createRoot(container).render(element)
    e.target.appendChild(container)
    
    menuContainer = container
  return
}

const ContextMenuElement = ({ children, options }) => {
    const arrayChildren = React.Children.toArray(children)

    const addedProps = {
        'data-has-context-menu': true,
        onContextMenu: (e) => {
            e.preventDefault()
            ContextMenu(e, options)
        }
    }
    return (
        React.Children.map(arrayChildren, child => (
            React.cloneElement(child, {...addedProps})
        ))
    )
}

export default ContextMenuElement