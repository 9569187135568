import React, { useEffect, useRef } from 'react'

import styled from 'styled-components'

const StyledContainer = styled.div`
    position: fixed;
    z-index: 999;

    width: 10rem;
    max-height: 100%;
    max-width: 100%;

    top: 20%;
    left: 50%;
    transform: translate(-50%);

    background-color: var(--bg-secondary);
    border-radius: 10px;
    box-shadow: -10px 10px 10px grey;

    padding: 1.5rem;
`

const StyledTitle = styled.div`
    margin-bottom: 1.5em;
    font-weight: bold;
`

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`

const PopUp = ({
    children,
    title='Text here',
    buttons=[],
    showPopUp=false,
    setShowPopup=()=>{}
}) => {
    const containerRef = useRef()

    const handleClickOutside = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setShowPopup(false)
        }
    }

    useEffect(() => {
        if (showPopUp) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [showPopUp])
    
    
    return (
        showPopUp && (
            <StyledContainer ref={containerRef} >
                {children ? (<>
                    {children}
                </>) : (<>
                    <StyledTitle>
                        {title}
                    </StyledTitle>

                    <StyledButtonContainer>
                        {buttons.map((button, index) => (
                            <React.Fragment key={index} >
                                {button}
                            </React.Fragment>
                        ))}   
                    </StyledButtonContainer>
                </>)}         
            </StyledContainer>
        )
    )
}

export default PopUp