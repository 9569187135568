import React, { useRef, useState } from 'react'

import { CloudUploadOutline } from 'react-ionicons'

import styled from 'styled-components'

const StyledContainer = styled.div`
    position relative;
    width: 100%;
    margin: 5px 0 5px;

    display: flex; 
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;
    padding: 1rem;
    border-radius: 10px;

    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

`

const StyledInput = styled.input`
    all: unset;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    height: 0; 
    width: 0;
`

const StyledPreviewContainer = styled.div`
    width: 100%;
    margin: 10px 0 10px;
    padding: 0 5px 0 5px;
    box-sizing: border-box;

    display: grid;
    gap: 5px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
`

const StyledPreview = styled.img`
    object-fit: contain;
    max-width: 100%
`

const StyledText = styled.div`
    margin-top: auto;
    margin-bottom: auto;

    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    text-overflow: ellipsis;
    overflow: hidden;
`


const FileInput = ({
    value,
    setValue=() => {},
    previewImage,
    text='Select Files',
    className,
    accept='image/*'
}) => {
    const inputRef = useRef()
    const [key, setKey] = useState(0)

    return (
        <>
        <StyledContainer >
            <StyledInput 
                key={key}
                type='file'
                className={className}
                accept={accept}
                onChange={(e) => {
                    setValue(e); 
                    setKey(prev => prev+1)
                }}
                ref={inputRef}
            />

            <CloudUploadOutline 
                className='hover-pointer'
                height='48px' 
                width='48px'
                onClick={() => inputRef.current.click()}
            />

            <StyledText >
                {text}
            </StyledText>
        </StyledContainer>

        {previewImage && (value && (
            <StyledPreviewContainer>
                <StyledPreview src={previewImage} />
                <StyledText>
                    {value.name}
                </StyledText>
            </StyledPreviewContainer>
        ))}
        </>
    )
}

export default FileInput