/*
    Purpose: Dynamic input providing similar functionality to the HTML default, 
      but with animated styling
    Author: Amos Gordon

    Latest Upadte: 6/24/2023
*/

import React, { useState } from 'react'
import { handleChange } from '../CommonUtils'
import styled from 'styled-components'

const StyledElementContainer = styled.div`
  display: inline-block;
  max-width: 100%;
`

const StyledInputContainer = styled.div`
  --bottomPadding: .25rem;
  --topPadding: 1.75rem;
  --xPadding: .2rem;

  position: relative;
  margin-top: var(--inputYGap, 5px);
  margin-bottom: var(--inputYGap, 5px);
`

const StyledPlaceHolder = styled.div`
  position: absolute;
  bottom: var(--bottomPadding);
  left: var(--xPadding);

  pointer-events: none;

  transition: transform .3s ease;
  backface-visibility: hidden;
  transform-origin: left;

  user-select: none;
  -webkit-user-select: none; 
  -ms-user-select: none;
`

const StyledInput = styled.input`
  all: unset;

  padding: var(--topPadding) var(--xPadding) var(--bottomPadding) var(--xPadding);

  text-align: start;

  box-sizing: border-box;

  border-bottom: 2px solid black;

  max-width: 100%;

  &[contenteditable=false] {
    pointer-events: none;
    background: linear-gradient(to top, var(--greyOut, rgba(128, 128, 128, 0.422)) 50%, transparent 50%);
  }

  &:focus, &:not(&[value=""]) {
    border-color: var(--primary, rgb(71, 128, 123));
  }

  &:focus + ${StyledPlaceHolder}, :not(&[value=""]) + ${StyledPlaceHolder} {
    transform: translateY(calc(-1*(var(--topPadding) - 25%))) scale(.8);
    color: var(--primary, rgb(71, 128, 123));
  }
`

const Input = ({
    value,
    setValue=()=>{},
    name,
    type='text',
    maxLength,
    placeholder="Type here...",
    className='',
    numbersOnly=false,
    contentEditable=true,
    isPhoneNumber=false,
    onEnter=()=>{}
}) => {
  const [error, setError] = useState()

  return (
    <StyledElementContainer>
      <StyledInputContainer className={className}>
          <StyledInput 
            type={type}
            value={value} 
            name={name}
            maxLength={isPhoneNumber ? 14 : maxLength}
            contentEditable={contentEditable}
            onChange={e => handleChange(e, numbersOnly, isPhoneNumber, setValue, setError)} 
            onBlur={() => {
              setError()
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onEnter()
              }
            }
          } />
          <StyledPlaceHolder>
              {placeholder}
          </StyledPlaceHolder>
      </StyledInputContainer>
      {error && (
        <div 
          style={{
            display: 'flex',
            color: 'var(--danger, red)'
          }}
        >
          <div
            style={{flex: '1', width: '0'}}
          >{error}</div>
        </div>
      )}
    </StyledElementContainer>
  )
}

export default Input