import React from 'react'
import styled from 'styled-components'

import { SearchOutline } from 'react-ionicons'


const StyledContainer = styled.div`
    max-width: 100%;
    height: 100%;
    min-height: 1.5rem;
    border-radius: 100vh;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.5);
`

const StyledSearchBar = styled.input`
    all: unset;

    padding: .5rem 1rem .5rem 1rem;
    width: 0;
    flex: 1;
`

const StyledButton = styled.div`
    cursor: pointer;
    background-color: var(--primary);
    aspect-ratio: 1 !important;
    height: 110%;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -2px 0px 4px rgba(0,0,0,0.5);
`

const SearchBar = ({
    value,
    name,
    setValue=()=>{},
    placeholder,
    onSubmit=()=>{},
}) => {
    return (
        <StyledContainer>
            <StyledButton
                onClick={() => {onSubmit()}}
            >
                <SearchOutline color={'var(--text-on-primary)'}/>
            </StyledButton>
            <StyledSearchBar 
                value={value}
                onChange={(e) => {setValue(e.target.value)}}
                placeholder={placeholder ? placeholder : 'Search...'}
                name={name}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      onSubmit()
                    }
                }}
            />
        </StyledContainer>
    )
}

export default SearchBar