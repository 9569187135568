import React, { useState, useEffect } from 'react'

import TextArea from '../components/global/inputs/placeholderAbove/TextArea'

import { AddCircleOutline, ArrowBack } from 'react-ionicons'

import ProfilePicture from '../images/default_teal.png'

import TokenData from '../components/functional/TokenData'


const id = TokenData()?.u_id


const DM = ({dm, className, onClick}) => {
  return (
    <div className={'messagesSideBarDM' + className} onClick={onClick}> 
      <img src={ProfilePicture} alt='Profile Picture' style={{objectFit: 'contain'}}/>

      <div className='messagesSideBarDMText'>
        <b>{dm.name}</b>
        <span>{dm.lastMessage.text}</span>
      </div>

      <div className='messagesSideBarDMDate'>
        <span>{(new Date (dm.lastMessage.datetime + ' UTC')).toLocaleDateString('en-us', {month:"short", day:"numeric"})}</span>
      </div>
    </div>
  )
}

const Message = ({message}) => {
  return (
    <div className={'messagesChatMessage' + (id == message.id ? ' isSelf' : '')}>
      <div>
        {message.sender}
      </div>
      <div className='messagesChatMessageText'>
        {message.text}
      </div>
      <div>
        {(new Date(message.datetime + ' UTC')?.toLocaleTimeString('en-us', {hour: "numeric", minute: 'numeric'}))}
      </div>
    </div>
  )
}

const NewDMSearch = () => {
  return (
    <div className='messagesNewDMContainer'>


    </div>
  )
}


const Messages = () => {
  const [messageList, setMessageList] = useState([])
  const [message, setMessage] = useState('')
  const [dms, setDms] = useState([])
  const [openDM, setOpenDM] = useState('')

  const getMessages = (id) => {
    setMessageList([
      { // newest
        sender: 'Amanda Beechy',
        id: 1,
        datetime: '2023-11-29 23:28:00', 
        text: 'Hello, how are you?',
      },
      { // oldest
        sender: 'Dev',
        id: 100,
        datetime: '2023-11-29 23:25:00', 
        text: 'Hi',
      }
    ])
  }

  useEffect(() => {
    getMessages(1) // TODO remove
    setDms([ // TODO use real data and remove
    {
      name: 'Ben Beechy',
      id: 4,
      profilePic: '',
      lastMessage: {
        datetime: "2023-11-29 23:25:00",
        text: "Hello",
        seen: 1
      }
    },
    {
      name: 'Amanda Beechy',
      id: 1,
      profilePic: '',
      lastMessage: {
        datetime: "2023-11-15 23:25:00",
        text: "Can we work on this project ASAP",
        seen: 0
      }
    },
    {
      name: 'Sean Feavel',
      id: 2,
      profilePic: '',
      lastMessage: {
        datetime: "2023-11-14 23:25:00",
        text: "Im ready to get to work",
        seen: 0
      }
    }
  ].sort((a, b) => new Date(a.lastMessage.datetime) + new Date(b.lastMessage.datetime)) /* TODO consider using SQL to sort */)
  }, [])
  

  return (
    <div className='messagesContainer'>
      <div className='messagesSideBar'>
        <h2>Messages</h2>

        <div className='messagesSideBarOptions'>
          <ArrowBack className='hover-pointer' onClick={() => {window.location.href = '/'}}/>
          <AddCircleOutline className='hover-pointer' onClick={() => {}}/>
        </div>

        <div className='messagesSideBarDMContainer'>
          {dms?.map((dm, index) => (
            <DM 
              dm={dm}
              key={index} 
              onClick={() => {
                setOpenDM(dm.id)
                getMessages(dm.id)
              }} 
              className={(dm.id == openDM ? ' selected' : '')}
            />
          ))}
        </div>
      </div>

      <div className='messagesMain'>        
        <NewDMSearch />
        <div className='messagesChat'>
          {messageList?.map((message, index) => (
            <Message message={message} key={index} />
          ))}
        </div>

        <TextArea className='messagesInput' placeholder='Type message...' value={message} setValue={(e) => {setMessage(e.target.value)}} maxLength={250}/>
      </div>
    </div>
  )
}

export default Messages