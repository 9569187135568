/*
    Purpose: Allows the application's context provider to dispatch actions such as sign in/out 
        from any page
    Author: Amos Gordon

    Latest Upadte: 6/24/2023
*/

import { createContext } from 'react'

const AuthContext = createContext()

export default AuthContext
