/*
    Purpose: This is a basic button
    Author: Amos Gordon

    Latest Upadte: 6/24/2023
*/

import React from 'react'
import styled from 'styled-components'


const StyledButton = styled.button`
  all: unset;

  padding: .5rem;

  border: none;
  border-radius: 5px;

  text-align: center;

  transition: box-shadow 0.3s ease-in-out;
  box-shadow: inset 0px 0px 10px rgba(0,0,0,0); 

  cursor: pointer;

  background-color: var(--primary, rgb(71, 128, 123));
  color: var(--text-on-primary, rgb(255, 255, 255));

  &:hover {
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
  }
`

// TODO consider dark mode for box shadow- may need variable 

const ButtonPrimary = ({
    onClick,
    text='Click me',
    className=''
}) => {
  return (
    <StyledButton className={className} onClick={onClick}>
        {text}
    </StyledButton>
  )
}


export default ButtonPrimary