import React from "react"
import styled from "styled-components"


const StyledTextArea = styled.textarea`
    all: unset;

    max-width: 100%;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;

    text-wrap: wrap;
    overflow-wrap: break-word;
    background-color: var(--bg-secondary, rgb(245, 249, 251));
    
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;

    &:hover, &:focus {
        box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
    }

    &.notEditable {
        pointer-events: none;
        background: var(--greyOut, rgba(128, 128, 128, 0.422));
    }
`

const TextArea = ({
    value,
    name,
    setValue,
    type="text",
    placeholder="Type here...",
    contentEditable=true,
    className='',
    onEnter=()=>{}
}) => {
  return (
    <StyledTextArea  
        type={type} 
        value={value} 
        name={name}
        placeholder={placeholder}
        className={className + (contentEditable ? '' : ' notEditable')}
        onChange={e => {setValue(e)}} 
        onKeyPress={(e) => {
            if (e.key === 'Enter') {
            onEnter()
            }
        }
    } />
  )
}

export default TextArea