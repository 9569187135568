import React from 'react';
import styled, { keyframes } from 'styled-components';

const bubbleRise = keyframes`
    0% {
        top: 100%;
        opacity: 0;
        transform: translateY(0);
    }
    50% {
        opacity: 1;
    }
    100% {
        top: -30%;
        opacity: 0;
        transform: translateY(-100%);
    }
`;

const StyledContainer = styled.div`
    background: linear-gradient(to bottom, #003366 0%, #000022 100%);
    color: #fff;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden; /* Ensure bubbles don't overflow */
`;

const StyledContent = styled.div`
    text-align: center;
    position: relative;
    z-index: 1;
    color: #fff;
    font-size: 24px;
    padding: 1rem;
`;

const Bubble = styled.div`
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    position: absolute;
    opacity: 0; /* Initially set opacity to 0 */
    animation: ${bubbleRise} ${({ duration }) => duration}s infinite;
    animation-delay: ${({ delay }) => delay}s;

    /* Randomize bubble position */
    top: ${({ top }) => top}%;
    left: ${({ left }) => left}%;
`;


const ErrorPage = () => {
    const getRandomValue = (min, max) => Math.random() * (max - min) + min;

    const generateBubbles = () => {
        const bubbles = [];
        const numBubbles = 10;

        for (let i = 0; i < numBubbles; i++) {
            const size = getRandomValue(10, 40);
            const delay = getRandomValue(0, 5);
            const top = getRandomValue(80, 100); 
            const left = getRandomValue(0, 100);
            const duration = getRandomValue(8, 15); 

            bubbles.push(
                <Bubble
                    key={i}
                    size={size}
                    delay={delay}
                    top={top}
                    left={left}
                    duration={duration}
                />
            );
        }

        return bubbles;
    };

    return (
        <StyledContainer>
            {generateBubbles()}
            <StyledContent>
                <h1>404</h1>
                <h2>Oops! Page not found</h2>
                <p>Looks like you've taken a deep dive into the unknown...</p>
            </StyledContent>
        </StyledContainer>
    );
};

export default ErrorPage;
