import React, {useState} from 'react'
import { 
    EyeOffOutline,
    TimeOutline, 
    FileTrayFullOutline,
    CheckmarkCircleOutline,
} from 'react-ionicons'

import ButtonPrimary from '../../../components/global/buttons/ButtonPrimary'
import CallAPI from '../../../components/functional/CallAPI'
import PopUp from '../../../components/global/PopUp'
import { useNotification } from '../../../components/global/Notifications'
import TokenData from '../../../components/functional/TokenData'


const userRole = TokenData()?.role

const StateDropDown = ({ id, jobState, setJobState }) => {
    const createNotification = useNotification()
    const [open, setOpen] = useState(false)
    const [showPopUp, setShowPopUp] = useState(false)
    const [tempState, setTempState] = useState(jobState)

    const options = [
        {
            value: 'inactive',
            element: <EyeOffOutline className="entityViewTitleButton" color={"var(--danger)"} />,
            role: 'manager',
        },
        {
            value: 'active',
            element: <TimeOutline className="entityViewTitleButton" color={"var(--neutral)"}/>,
        },
        {
            value: 'pending_review',
            element: <FileTrayFullOutline className="entityViewTitleButton" color={"var(--warn)"}/>,
        },
        {
            value: 'complete',
            element: <CheckmarkCircleOutline className="entityViewTitleButton" color={"var(--success)"} />,
            role: 'manager',
        }
    ].filter(option => (!id && option.value !== 'pending_review' && option.value !== 'complete') || id)

    const jobStateInfo = {
        'inactive': {
            colorIndicator: 'var(--danger)',
            term: 'Inactive'
        },
        'active': {
            colorIndicator: 'var(--neutral)',
            term: 'In Progress',
        },
        'pending_review': {
            colorIndicator: 'var(--warn)',
            term: 'Pending Review',
        },
        'complete': {
            colorIndicator: 'var(--success)',
            term: 'Complete',
        }
    }

    const handleSubmit = () => {
        if (!id) return
        setOpen(false)
        CallAPI('/api/v1/job/state', 'PATCH', {
            id: id,
            state: tempState,
        })
        .then(result => {
            if (result.error !== undefined) return (
                createNotification({
                    type: 'danger',
                    message: <span><b>Could not change state:</b>{result.error}</span>,
                    timeout: 3500,
                })
            )

            return setJobState(tempState)
        })
    }


    return (
        <>
        {id && 
            <PopUp 
                title={<span>Change job state to <span style={{color: jobStateInfo[tempState].colorIndicator}}>{jobStateInfo[tempState].term}</span>?</span>}
                buttons={[
                    <ButtonPrimary 
                        text='Yes' 
                        onClick={() => {
                            setShowPopUp(false)
                            handleSubmit()
                        }}
                    />,
                    <ButtonPrimary 
                        text='No' 
                        className='bg-danger'
                        onClick={() => {
                            setShowPopUp(false)
                            setOpen(false)
                        }}
                    />
                ]}
                showPopUp={showPopUp}
                setShowPopup={setShowPopUp}
            />
        }
        <div className='entityViewStateDropDown'>
            {options
            .filter(option => option.value === jobState)
            .map((option, index) => (
                React.cloneElement(option.element, {
                    onClick: () => setOpen(!open),
                    key: index,
                })
            ))
            }
            
            <div className={'entityViewStateDropDownContentContainer' + (open ? ' open' : '')}>
                <div className='entityViewStateDropDownContent'>
                    {options
                    .filter(option => (option.value !== jobState && (option.role === userRole || !option.role)))
                    .map((option, index) => (
                        React.cloneElement(option.element, {
                            onClick: () => {
                                if (id) {
                                    setTempState(option.value)
                                    setShowPopUp(true)
                                } else {
                                    setJobState(option.value)
                                    setOpen(false)
                                }
                            },
                            key: index,
                        })
                    ))}
                </div>
            </div>
        </div>
        </>
    )
}

export default StateDropDown