/*
    Purpose: This is a basic styled date input
    Author: Amos Gordon

    Latest Upadte: 6/24/2023
*/

import React, { useRef } from "react"
import styled from 'styled-components'


const StyledDate = styled.input`
    all: unset;
    height: 0;
    width: 0;
    display: block;
`

const StyledInput = styled.input`
    all: unset;
    text-align: center;
    width: 10rem;

    box-sizing: border-box;

    border: none;
    background-color: var(--bg-secondary);

    padding: 10px;
    border-radius: 5px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

    max-width: 100%;

    margin-top: var(--inputYGap, 5px);
    margin-bottom: var(--inputYGap, 5px);

    &:hover, &:focus {
        box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.3);
    }
`

const DateInput = ({
    value,
    setValue=()=>{},  
    name, 
}) => {
    const datePickerRef = useRef(null)

    const formatDate = (date) => {
        if (!date) return ''

        const split = date.split('-')
        return `${split[1]}/${split[2]}/${split[0]}`
    }

    return (

        <div>
            <StyledInput
                type="text" 
                value={formatDate(value)}
                onClick={() => {
                    datePickerRef.current?.focus()
                    datePickerRef.current?.showPicker()}
                }
                readOnly={true}
                placeholder="mm/dd/yyyy"
            />
            <StyledDate  
                type={'date'} 
                value={''} 
                name={name}
                onChange={e => setValue(e)} 
                ref={datePickerRef}
            />           
        </div>
    )
}
  
export default DateInput