import React, { useState } from 'react'

import {
    //TODO remove when ready for message page || ChatbubbleEllipsesOutline,
    ExtensionPuzzleOutline,
    OptionsOutline,
    HammerOutline,
    PersonAddOutline,
    CarOutline,
    LibraryOutline, 
    MenuOutline,
    CodeSlashOutline,
} from 'react-ionicons'

import LogoShort from '../../images/logo_short.png'
import TokenData from '../../components/functional/TokenData'


const userRole = TokenData()?.role

const links = [
    /*TODO remove when ready for message page ||{
        name: 'Messages',
        dest: '/messages',
        icon: <ChatbubbleEllipsesOutline />
    }, */
    {
        name: 'Archive',
        dest: '/archive',
        icon: <LibraryOutline />,
    },
    {
        name: 'Job',
        dest: '/management/jobs',
        icon: <HammerOutline />,
        role: 'manager',
    },
    {
        name: 'User',
        dest: '/management/users',
        icon: <PersonAddOutline />,
        role: 'manager',
    },
    {
        name: 'Vehicle',
        dest: '/management/vehicles',
        icon: <CarOutline />,
        role: 'manager',
    },
    {
        name: 'Settings',
        dest: '/settings',
        icon: <OptionsOutline />,
    },
    {
        name: 'Scraper',
        dest: '/plugins/scraper',
        icon: <CodeSlashOutline />,
        role: 'manager',
    }
].filter(link => userRole === link.role || !link.role)


const Sidebar = () => {
    const [menuOpen, setMenuOpen] = useState(false)

    const SidebarLink = ({ text, to, icon }) => {
        return (
            <>
                <div className="sidebarItem" onClick={() => {window.location.href = to}}>
                    {icon}
                    {text}
                </div>
            </>
        )
    }

  return (
    <div className='sidebar'>
        <div className={'sidebarMenu' + (menuOpen ? ' open' : '')} >
            <div className='sidebarMenuContents'>
                {links.map((link, index) => (
                    <SidebarLink key={index} text={link.name} to={link.dest} icon={link.icon} />
                ))}

            </div>
        </div>

        <img className='sidebarLogo' src={LogoShort} alt='Arcdium Logo'/>
        <div className="sidebarMenuButton" onClick={() => {setMenuOpen(!menuOpen)}}><MenuOutline /></div>

        <div className='sidebarScrollView'>
            {links.map((link, index) => (
                <SidebarLink key={index} text={link.name} to={link.dest} icon={link.icon} />
            ))}  
        </div>
    </div>
  )
}

export default Sidebar