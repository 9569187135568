import React from 'react'

import Sidebar from './Sidebar'
import DashboardMain from './DashboardMain'

const Dashboard = () => {
  return (
    <div className='dashboardContainer'>
      <Sidebar />
      <DashboardMain />
    </div>   
  )
}

export default Dashboard